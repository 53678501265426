import React, {useEffect, useRef, useState} from 'react';
import {IconButton, Stack} from "@mui/material";
import MicIcon from '@mui/icons-material/Mic';
import Tooltip from '@mui/material/Tooltip';
import toast from "react-hot-toast";

const AudioRecorder = ({ onRecordingComplete, clearRecording }) => {

    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const [audioBlob, setAudioBlob] = useState(null);

    useEffect(() => {
        if (clearRecording) {
            clearRecording.current = handleClearRecording;
        }
    }, [clearRecording]);

    const handleClearRecording = () => {
        setAudioBlob(null);
        audioChunks.current = [];
    };

    const handleStartRecording = async () => {
        audioChunks.current = [];

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorder.current = new MediaRecorder(stream);

            setIsRecording(true);

            mediaRecorder.current.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            };

            mediaRecorder.current.onstop = () => {
                const blob = new Blob(audioChunks.current, { type: 'audio/wav' });
                setAudioBlob(blob);
                onRecordingComplete(blob);
            };

            mediaRecorder.current.start();
        } catch (err) {
            toast.error('Microphone access denied or not found.');
            setIsRecording(false);
        }
    };

    const handleStopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
    };

    const handlePlayAudio = () => {
        if (audioBlob) {
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            audio.play();
        }
    };

    return (
        <Stack width={audioBlob ? "100%" : "fit-content"}>
            {!audioBlob && <Tooltip title="Voice Recording"><IconButton onClick={() => {
                !isRecording ? handleStartRecording() : handleStopRecording();
            }}>
                <MicIcon sx={isRecording ? { color: "red" } : { color: "gray" }} />
            </IconButton></Tooltip>}
        </Stack>
    );
};
export default AudioRecorder;