import React, {useEffect, useRef, useState} from 'react';
import {Grid, IconButton, TextField, Tooltip, Typography,} from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from "react-hot-toast";
import AudioRecorder from "./audioRecorder";
import AudioTranscriber from "./audioTranscriber";
import ReactAudioPlayer from "react-audio-player";
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import CloseIcon from '@mui/icons-material/Close';

const {REACT_APP_BACKEND_API_URL} = process.env;

const CreateMessage = ({messages, setMessages, channelId, threadId, parentMessageId}) => {
    const [mediaFile, setMediaFile] = useState(null);
    const [mediaType, setMediaType] = useState('');
    const [message, setMessage] = useState('');
    const [transcribedMessage, setTranscribedMessage] = useState('');
    const [partialTranscribedMessage, setPartialTranscribedMessage] = useState('');
    const [isMediaSelected, setIsMediaSelected] = useState(false);
    const [submitting, setIsSubmitting] = useState(false);
    const fileInputRef = useRef(null);
    const videoRef = useRef(null);
    const clearRecordingRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if(transcribedMessage.length || partialTranscribedMessage.length) {
            setMessage(transcribedMessage + partialTranscribedMessage)
        }
        if (inputRef.current) {
            // Move caret to the end of the text
            const input = inputRef.current.querySelector('input');
            if (input) {
                input.focus();
                input.scrollLeft = input.scrollWidth;
            }
        }
    }, [transcribedMessage, partialTranscribedMessage]);

    const handleTranscriptionStart = () => {
        setTranscribedMessage(prevState => message)
    }

    const handleTranscriptionEnd = () => {
        setPartialTranscribedMessage("")
        setTranscribedMessage(prevState => message)
    }

    const handleTranscriptionChange = (msg, is_partial) => {
        if(is_partial) {
            setPartialTranscribedMessage(msg)
        } else {
            setPartialTranscribedMessage("")
            setTranscribedMessage(prevMsg => prevMsg + " " + msg)
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setMediaFile(file);
            setIsMediaSelected(true);

            // Determine media type based on file type
            if (file.type.startsWith('video')) {
                setMediaType('video');
            } else if (file.type.startsWith('image')) {
                setMediaType('image');
            } else if (file.type.startsWith('audio')) {
                setMediaType('audio');
            } else {
                setMediaType('other');
            }

            // Reset video playback to ensure it starts from the beginning
            if (videoRef.current) {
                videoRef.current.load();
            }
        }
    };

    const handleUploadButtonClick = (event) => {
        fileInputRef.current.click();
    };

    const handleRecordingComplete = (blob) => {
        setMediaFile(blob);
        setIsMediaSelected(true);
        setMediaType('audio');
    };

    const clearMedia = () => {
        setMediaFile(null);
        setMediaType('');
        setIsMediaSelected(false);
        setTranscribedMessage(prevState => '')
        setMessage('');
        if (clearRecordingRef.current) {
            clearRecordingRef.current();
        }
    };

    const handleSubmit = () => {
        if (message || mediaFile) {
            setIsSubmitting(true);
            const formData = new FormData();
            formData.append('media_file', mediaFile);
            formData.append('media_type', mediaType);
            formData.append('message', message);
            if (threadId !== null) {
                formData.append('thread_id', threadId);
            }
            if (parentMessageId !== null) {
                formData.append('parent_message_id', parentMessageId);
            }

            const accessToken = Cookies.get('access_token');
            axios.post(`${REACT_APP_BACKEND_API_URL}/api/channels/${channelId}/message/create/`, formData, {
                headers: {
                    Authorization: `Token ${accessToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(response => {
                    setMessages([
                        response.data.message,
                        ...messages
                    ])
                    toast.success('Message saved successfully!');
                    clearMedia();
                    setIsSubmitting(false);
                })
                .catch(error => {
                    let err_message = error
                    if (error?.response?.data?.message) {
                        err_message = error?.response?.data;
                    }
                    toast.error('Failed to send message.', err_message);
                    setIsSubmitting(true);
                });
        } else {
            toast.error('Incomplete form data.');
        }
    };

    if (typeof (channelId) !== "number") {
        return null;
    }

    const mediaPreview = isMediaSelected ? (
        <Grid item xs={12} sm={8} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}
              sx={{height: '200px', maxHeight: '200px'}}>
            <IconButton onClick={clearMedia} sx={{position: 'relative', top: 0, right: 0, zIndex: 10}}>
                <CloseIcon sx={{color: "white"}}/>
            </IconButton>
            {mediaType === 'video' ? (
                <video
                    controls
                    preload="auto"
                    ref={videoRef}
                    style={{width: '100%', maxHeight: '180px', borderRadius: '10px'}}
                >
                    <source src={URL.createObjectURL(mediaFile)} type={mediaFile.type}/>
                    Your browser does not support the video tag.
                </video>
            ) : mediaType === 'image' ? (
                <img
                    src={URL.createObjectURL(mediaFile)}
                    alt="Selected Image"
                    style={{width: 'auto', maxHeight: '180px', borderRadius: '10px'}}
                />
            ) : mediaType === 'audio' ? (
                <ReactAudioPlayer
                    src={URL.createObjectURL(mediaFile)}
                    controls
                    style={{width: '100%'}}
                />
            ) : <Typography color={"red"}>Attachment Preview not Available</Typography>}
        </Grid>
    ) : null;

    const canSubmit = message !== '' || mediaFile !== null;

    return (
        <Grid container display={"flex"} alignItems="center" justifyContent="center"
              sx={{height: '50px', maxHeight: '50px', borderTop: "1px solid black", width: "95%", mx: "2.5%"}}
              ref={setAnchorEl}>
            <Grid item xs={8}>
                <TextField
                    size="small"
                    label="Enter Message..."
                    variant="outlined"
                    value={message}
                    onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && message.trim() !== '' ? handleSubmit() : null}
                    onChange={(e) => setMessage(e.target.value)}
                    fullWidth
                    autoFocus
                    ref={inputRef}
                />
            </Grid>
            <Grid item xs={1}>
                <input
                    type="file"
                    accept="*"
                    capture="camera microphone"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{display: 'none'}}
                />
                <IconButton onClick={handleUploadButtonClick} sx={isMediaSelected ? {color: "#25d366"} : {}}>
                    <AttachFileIcon sx={{color: "inherit"}}/>
                </IconButton>
            </Grid>
            <Grid item xs={1}>
                <AudioTranscriber
                    onTranscriptionDataUpdate={handleTranscriptionChange}
                    onTranscriptionStart={handleTranscriptionStart}
                    onTranscriptionEnd={handleTranscriptionEnd}
                />
            </Grid>
            <Grid item xs={1}>
                <AudioRecorder onRecordingComplete={handleRecordingComplete}
                               clearRecording={clearRecordingRef}/>
            </Grid>
            <Grid item xs={1}>
                <Tooltip title={!canSubmit ? 'Message is empty' : ''}>
                    <span>
                    <IconButton onClick={handleSubmit} disabled={!canSubmit || submitting}
                                sx={canSubmit ? {color: "#1877f2"} : submitting ? {color: "#2970cb"} : {color: "#a4a2a2"}}>
                        {submitting ? <ScheduleSendIcon sx={{color: "inherit"}}/> : <SendIcon sx={{color: "inherit"}}/>}
                    </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            {isMediaSelected && anchorEl && (
                <div style={{
                    position: 'absolute',
                    top: anchorEl.getBoundingClientRect().top - 200,
                    left: anchorEl.getBoundingClientRect().left,
                    width: anchorEl.offsetWidth,
                    zIndex: 9,
                    backgroundColor: '#000000CC',
                }}>
                    {mediaPreview}
                </div>
            )}
        </Grid>
    );
};

export default CreateMessage;
