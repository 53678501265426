// authActions.js
import axios from 'axios';
import {LOGIN_SUCCESS, LOGOUT_SUCCESS, SET_ERROR, UPDATE_USER_SETTINGS} from "../constants/login.constant";
import Cookies from 'js-cookie';
import toast from "react-hot-toast";

const {REACT_APP_BACKEND_API_URL} = process.env;

export const loginSuccess = (user, user_settings) => ({
    type: LOGIN_SUCCESS,
    user: user,
    user_settings: user_settings
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const setError = (error) => ({
    type: SET_ERROR,
    payload: error,
});

export const googleLogin = (code) => async (dispatch) => {
    try {
        const res = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/auth/google?code=${code}`);
        const {id, first_name, last_name, email, username} = res.data.user;
        const user_settings = res.data.user_settings;
        const picture = res.data.picture;
        const {access_token} = res.data;
        localStorage.setItem('user_id', id);
        localStorage.setItem('first_name', first_name);
        localStorage.setItem('last_name', last_name);
        localStorage.setItem('email', email);
        localStorage.setItem('username', username);
        localStorage.setItem('picture', res.data.picture)

        Cookies.set("access_token", access_token);
        toast.success('Logged in successfully as ' + first_name + " " + last_name);
        dispatch(loginSuccess({first_name, last_name, email, username, picture}, user_settings));
    } catch (err) {
        dispatch(setError(err.message));
    }
};


export const getUserFromLocalStorage = () => {
    const user_id = localStorage.getItem('user_id');
    const first_name = localStorage.getItem('first_name');
    const last_name = localStorage.getItem('last_name');
    const email = localStorage.getItem('email');
    const username = localStorage.getItem('username');
    const picture = localStorage.getItem('picture');

    if (user_id && first_name && last_name && email && username && picture) {
        return {
            user_id,
            first_name,
            last_name,
            email,
            username,
            picture
        };
    } else {
        return null;
    }
};


export const loadUser = () => async (dispatch) => {
    try {
        // Retrieve tokens from cookies/localStorage
        const accessToken = Cookies.get('access_token') || localStorage.getItem('access_token');

        if (!accessToken) {
            return;
        }

        const user = getUserFromLocalStorage();

        let user_settings = null;
        if (user && user.user_id) {
            const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/user/settings/${user.user_id}/`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                }
            })
            user_settings = response.data;
        }

        dispatch({
            type: LOGIN_SUCCESS,
            user: user,
            user_settings: user_settings
        });
    } catch (error) {
        dispatch({type: SET_ERROR});
    }
};

export const updateUserSettings = (user_settings) => async (dispatch) => {
    try {
        const accessToken = Cookies.get('access_token');
        const user = getUserFromLocalStorage();
        if (user && user.user_id) {
            const response = await axios.patch(`${REACT_APP_BACKEND_API_URL}/api/user/settings/${user.user_id}/`, user_settings, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                }
            });
            dispatch({
                type: UPDATE_USER_SETTINGS,
                payload: response.data
            });
        }
    } catch (error) {
        console.log(error.response?.data || error.message);
    }
};