import React from 'react';
import Typography from '@mui/material/Typography';
import toast from 'react-hot-toast';
import {Chip} from "@mui/material";
import {updateFolderAssignee, updateLinkAssignee} from "../redux/actions/folders.action";
import {useDispatch} from "react-redux";

const {REACT_APP_BACKEND_API_URL} = process.env;


const AssigneeList = ({folderData, onSelectAssignee, users, itemDetails, itemType}) => {
    const dispatch = useDispatch();

    const handleAssigneeChange = async (id, isDelete = false) => {
        if (itemDetails.employees.length === users.length && !isDelete) {
            toast.success('All users are already assigned to this folder.');
            return;
        }
        if (itemType === 'folder') {
            dispatch(updateFolderAssignee(id, onSelectAssignee, folderData, isDelete))
        } else {
            dispatch(updateLinkAssignee(id, onSelectAssignee, folderData, isDelete))
        }
    };

    return (
        <div style={{marginTop: -20, width:"100%"}}>
            {itemDetails.employees && itemDetails.employees.length > 0 && (
                <div style={{marginTop: 20, display: 'flex', flexWrap: 'nowrap', overflowX: 'auto'}}>
                    {itemDetails.employees.map((employee) => (
                        <Chip
                            key={employee.id}
                            avatar={<img
                                src={employee.picture}
                                alt="Employee's Picture"
                                style={{
                                    height: 20,
                                    width: 20,
                                    borderRadius: '50%',
                                }}
                            />}
                            label={<Typography variant="body1" style={{fontSize: 11}}>
                                {`${employee.first_name} ${employee.last_name}`}
                            </Typography>}
                            onDelete={() => handleAssigneeChange(employee.id, true)}
                            style={{marginBottom: 10, marginRight: 10}}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}


export default AssigneeList;