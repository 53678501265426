import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {
    deleteFolderSuccess,
    fetchChildFolders,
    folderAssigneeSuccess,
    renameFolderFailure,
    renameFolderSuccess, updateArchiveStatus
} from "../redux/actions/folders.action";
import Typography from "@mui/material/Typography";
import {
    Chip,
    Divider, FormControl,
    IconButton, InputLabel, MenuItem,
    Modal, Select, Stack,
    TextField
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import axios from "axios";
import Grid from "@mui/material/Grid";

const {REACT_APP_BACKEND_API_URL} = process.env;

const AggregatorView = ({folderId, data, onDataChange}) => {
    const [key, setKey] = useState(null);
    const [value, setValue] = useState(null);
    const [summary, setSummary] = useState({});

    useEffect(() => {
        getAggregatorSummary();
    }, []);

    const handleChangeKey = (event) => {
        setKey(event.target.value);
    };

    const handleChangeValue = (event) => {
        setValue(event.target.value);
    };

    const getAggregatorSummary = async () => {
        try {
            const accessToken = Cookies.get('access_token');
            const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/aggregator/summary/${folderId}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            setSummary(response.data)
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error("Error - ", error?.response?.data);
            } else {
                toast.error("Aggregator Summary Retrieval Failed!");
            }
        }
    }

    const onAddNewKeyPair = async () => {
        if (key === null || value === null) {
            toast.error("Key or value is empty.")
            return;
        }
        try {
            const accessToken = Cookies.get('access_token');
            const data = {"key": key, "value": value}
            const response = await axios.post(`${REACT_APP_BACKEND_API_URL}/api/aggregator/add/${folderId}`, data, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            onDataChange(response.data)
            toast.success(`Added successfully`);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error("Error - ", error?.response?.data);
            } else {
                toast.error("Aggregator Data addition failed!");
            }
        }

    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={7}>
                    <Typography variant="h6" gutterBottom style={{marginBottom: 5, marginTop: 5}}>
                        Aggregator Keys:
                    </Typography>
                    <Divider style={{marginBottom: 5}}/>
                    <Stack direction="row" spacing={2}>
                        <Typography display={"flex"} alignItems={"center"}>Add New: </Typography>
                        <FormControl sx={{m: 1, minWidth: 120}} size="small" dire>
                            <InputLabel id="demo-select-small-label">Key</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={key}
                                label="Age"
                                onChange={handleChangeKey}
                            >
                                <MenuItem value={null}>
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"priority"}>Priority</MenuItem>
                                <MenuItem value={"risk"}>Risk</MenuItem>
                                <MenuItem value={"time"}>Time in Hrs</MenuItem>
                                <MenuItem value={"eta"}>ETA</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            required
                            size="small"
                            id="value-aggregator"
                            label="Value"
                            value={value}
                            onChange={handleChangeValue}
                            sx={{maxWidth: "200px"}}
                        />
                        <IconButton onClick={onAddNewKeyPair}>
                            <SaveIcon/>
                        </IconButton>
                    </Stack>
                    {data?.map(({id, key, value}, _) => (
                        <Chip
                            key={id}
                            label={key + " -> " + value}
                            style={{margin: '5px', fontSize: '14px', height: '26px'}}
                        />
                    ))}
                </Grid>
                <Grid item xs={12} md={5}>
                    <Typography variant="h6" gutterBottom style={{marginBottom: 5, marginTop: 5}}>
                        Aggregator Summary:
                    </Typography>
                    <Divider style={{marginBottom: 5}}/>
                    <Grid container>
                    {Object.entries(summary)?.map(([key, value]) => (
                        <Grid item xs={12} md={6}><Chip
                            key={key}
                            label={`${key}: ${value !== null ? value : 'N/A'}`}
                            style={{ margin: '5px', fontSize: '14px', height: '26px', fontWeight: 'bold' }}
                        /></Grid>
                    ))}
                    </Grid>
                </Grid>
            </Grid>
            <Divider style={{marginBottom: 5}}/>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    renameFolderSuccess,
    deleteFolderSuccess,
    folderAssigneeSuccess,
    updateArchiveStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(AggregatorView);
