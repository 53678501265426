import React, {useState, useEffect, useRef} from 'react';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import toast from 'react-hot-toast';
import TextField from "@mui/material/TextField";
import {Box, Chip} from "@mui/material";
import {updateFolderAssignee, updateLinkAssignee} from "../redux/actions/folders.action";
import {useDispatch} from "react-redux";

const {REACT_APP_BACKEND_API_URL} = process.env;

const FolderAssigneeDropdown = ({folderData, onSelectAssignee, itemDetails, itemType}) => {
    const [showSearch, setShowSearch] = useState(false);
    const autocompleteRef = useRef(null);
    const dispatch = useDispatch();

    const handleSetShowSearch = () => {
        setShowSearch(!showSearch);
    };

    const handleAssigneeChange = async (id, isDelete = false) => {
        if (itemDetails.employees && itemDetails.employees.length === folderData.users.length && !isDelete) {
            toast.success('All users are already assigned to this folder.');
            setShowSearch(false);
            return;
        }
        if (itemType === 'folder') {
            dispatch(updateFolderAssignee(id, onSelectAssignee, folderData, isDelete))
        } else {
            dispatch(updateLinkAssignee(id, onSelectAssignee, folderData, isDelete))
        }

    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                showSearch &&
                autocompleteRef.current &&
                !autocompleteRef.current.contains(event.target)
            ) {
                setTimeout(() => {
                    setShowSearch(false);
                }, 100);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showSearch]);

    return (
        <div style={{}}>
            {!showSearch && <Box
                onClick={handleSetShowSearch}
                style={{
                    backgroundColor: '#f8f9fb',
                    borderRadius: '10px',
                    padding: '12px',
                    height: '60px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <div style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                    <Typography variant="body1">
                        Add Assignee
                    </Typography>
                </div>
            </Box>}

            {showSearch && (
                <Autocomplete
                    style={{
                        // position: 'absolute',
                        // zIndex: 100,
                        // backgroundColor: 'white',
                        width: '250px',
                        top: '120px',
                    }}
                    ref={autocompleteRef}
                    // blurOnSelect={true}
                    open={true}
                    options={folderData.users}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            handleAssigneeChange(newValue.id);
                        } else {
                            handleAssigneeChange(null)
                        }
                    }}
                    value={itemDetails.assignee}

                    getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                    renderOption={(props, option) => {
                        let backgroundColor = 'white';
                        if (itemDetails.assignee && option.id === itemDetails.assignee.id) {
                            backgroundColor = '#BEBEBE';
                        }
                        return <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: 5,
                                // backgroundColor: backgroundColor
                            }}
                            {...props}
                        >
                            <img
                                src={option.picture}
                                alt="Assignee's Picture"
                                style={{
                                    height: 30,
                                    width: 30,
                                    borderRadius: '50%',
                                    marginRight: 10,
                                }}
                            />
                            <Typography
                                variant="body1">{`${option.first_name} ${option.last_name}`}</Typography>
                        </div>
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoFocus
                            placeholder="Search People"
                            variant="outlined"
                            fullWidth
                            // style={{marginTop: '10px'}}
                        />
                    )}
                />
            )}
        </div>
    );
};

export default FolderAssigneeDropdown;
