import React, {useState} from 'react';
import {Box, Button, List, ListItemButton, ListItemText, Stack} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Cookies from "js-cookie";
import axios from "axios";
import toast from "react-hot-toast";
import Grid from "@mui/material/Grid";

const {REACT_APP_BACKEND_API_URL} = process.env;

const ChannelList = ({channels, setChannels, selectedChannel, setSelectedChannel}) => {
    const [newChannelName, setNewChannelName] = useState('');
    const [newChannelDescription, setNewChannelDescription] = useState('');
    const [createSectionOpen, setCreateSectionOpen] = useState(false);

    const createNewChannel = () => {
        const formData = new FormData();
        formData.append('name', newChannelName);
        formData.append('description', newChannelDescription);

        const accessToken = Cookies.get('access_token');
        axios.post(`${REACT_APP_BACKEND_API_URL}/api/channels/`, formData, {
            headers: {
                Authorization: `Token ${accessToken}`,
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                setChannels([
                    ...channels,
                    response.data
                ])
                setCreateSectionOpen(false)
                toast.success('Channel created successfully!');
            })
            .catch(error => {
                let err_message = error
                if (error?.response?.data?.message) {
                    err_message = error?.response?.data;
                }
                toast.error('Failed to create channel.', err_message);
            });
    };

    const channelCreationView = () => {
        if (createSectionOpen) {
            return (
                <Grid container spacing={1} px={1.5} className={"full-width"}>
                    <Grid item xs={12}>
                        <input type="text" value={newChannelName} onChange={(e) => setNewChannelName(e.target.value)}
                               placeholder="Channel Name"/>
                    </Grid>
                    <Grid item xs={12}>
                        <input type="text" value={newChannelDescription}
                               onChange={(e) => setNewChannelDescription(e.target.value)}
                               placeholder="Channel Description"/>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="outlined" onClick={() => setCreateSectionOpen(false)}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={createNewChannel}>
                            Create
                        </Button>
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Button variant="outlined" endIcon={<AddCircleOutlineIcon/>} onClick={() => setCreateSectionOpen(true)}>
                    Create New Channel
                </Button>);
        }
    }

    return (
        <Stack className={"full-parent"}>
            <Box display={"flex"} flexDirection="column" className={"full-parent"}>
                <Box my={2}
                     display="flex"
                     alignItems="center"
                     justifyContent="center">
                    {channelCreationView()}
                </Box>
                <List sx={{p: 1, flex: '1', overflowY: 'auto'}}>
                    {channels.map(channel => (
                        <ListItemButton key={channel.id} selected={selectedChannel === channel.id}
                                        onClick={() => setSelectedChannel(channel.id)}
                                        sx={{
                                            backgroundColor: selectedChannel === channel.id ? 'lightblue' : 'white',
                                            border: '1px solid black',
                                            marginBottom: '8px',
                                            '&:hover': {
                                                backgroundColor: selectedChannel === channel.id ? '#797676' : '#e5e5e5',
                                            },
                                            '&.Mui-selected': {
                                                backgroundColor: '#25d366',
                                                '&:hover': {
                                                    backgroundColor: '#61d78d',
                                                },
                                            },
                                        }}
                        >
                            <ListItemText primary={channel.name}/>
                        </ListItemButton>
                    ))}
                </List>
            </Box>
        </Stack>
    );
};

export default ChannelList;