import {TOGGLE_KEYBOARD_NAVIGATION, UPDATE_ITEM_ID_TO_MOVE} from "../constants/global.constant";


const initialState = {
    keyboardNavigationActive: true,
    itemIdToMove: null
};

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_KEYBOARD_NAVIGATION:
            return {
                ...state,
                keyboardNavigationActive: action.payload,
            };
        case UPDATE_ITEM_ID_TO_MOVE:
            return {
                ...state,
                itemIdToMove: action.payload,
            }
        default:
            return state;
    }
};

export default globalReducer;
