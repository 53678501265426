import React from 'react';
import Typography from "@mui/material/Typography";
import Linkify from 'react-linkify';
import Link from '@mui/material/Link';

const LinkifyTypography = ({ children, ...props }) => {

    const LinkHelper = ({href, children}) => (
        <Link href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </Link>
    );

    return (<Typography {...props}>
        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
            <LinkHelper href={decoratedHref} key={key}>
                {decoratedText}
            </LinkHelper>
        )}>
            {children}
        </Linkify>
    </Typography>);
};

export default LinkifyTypography;