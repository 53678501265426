import React, {useEffect, useState} from 'react';
import Cookies from "js-cookie";
import {connect} from "react-redux";
import MessageList from "../../components/messagelist";
import {useNavigate} from "react-router-dom";
import ChannelList from "../../components/channelList";
import axios from "axios";
import Grid from "@mui/material/Grid";
import toast from "react-hot-toast";
import ThreadView from "../../components/ThreadView";
import {Box, Drawer, IconButton, Modal} from "@mui/material";
import {Close as CloseIcon} from "@mui/icons-material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const {REACT_APP_BACKEND_API_URL} = process.env;

const ChannelComponent = (access_token) => {
    const [messages, setMessages] = useState([]);
    const [channels, setChannels] = useState([]);
    const [channelPanelOpenForMobile, setChannelPanelOpenForMobile] = useState(false);
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [activeThread, setActiveThread] = useState(null);
    const [parentMessageThread, setParentMessageThread] = useState(null);
    const history = useNavigate();

    const mobileScreen = window?.innerWidth <= 600;


    useEffect(() => {
        if (!access_token) {
            history('/');
        }
    }, [access_token]);

    useEffect(() => {
        // Fetch channels from the API
        const fetchChannels = async () => {
            try {
                const accessToken = Cookies.get('access_token');
                const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/channels/`, {
                    headers: {
                        'Authorization': `Token ${accessToken}`, 'Content-Type': 'application/json',
                    },
                });
                setChannels(response.data);
            } catch (error) {
                if (error?.response?.data?.message) {
                    toast.error("Error - ", error?.response?.data);
                } else {
                    toast.error('Error fetching channels:', error);
                }
            }
        };
        fetchChannels();
    }, []);

    useEffect(() => {
        if (channels.length > 0) {
            setSelectedChannel(channels[0].id)
        }
    }, [channels]);

    useEffect(() => {
        setActiveThread(null);
        setParentMessageThread(null);
        setChannelPanelOpenForMobile(false);
    }, [selectedChannel]);

    const openFullscreenImage = (imageUrl) => {
        setFullscreenImage(imageUrl);
    };

    const closeFullscreenImage = () => {
        setFullscreenImage(null);
    };

    const prepareActiveThread = (threadId, parentMessageId) => {
        setActiveThread(threadId);
        setParentMessageThread(parentMessageId);
    }

    const requiredChannel = channels.find(item => item.id === selectedChannel);
    const channelName = requiredChannel ? requiredChannel.name : 'N/A';

    return (<div className={"full-parent"} style={{height: 'calc(100% - 60px)'}}>
        <Grid container className={"full-parent"}>
            {mobileScreen ? (<React.Fragment>
                <IconButton sx={{position: 'absolute', top: '75px', left: '5px', zIndex: 20}}
                            onClick={() => setChannelPanelOpenForMobile(true)}>
                    <ArrowBackIosIcon/>
                </IconButton>
                <Drawer
                    anchor="left"
                    open={channelPanelOpenForMobile}
                    onClose={() => setChannelPanelOpenForMobile(false)}
                >
                    <ChannelList channels={channels} setChannels={setChannels} selectedChannel={selectedChannel}
                                 setSelectedChannel={setSelectedChannel}/>
                </Drawer>
            </React.Fragment>) : (<Grid item xs={0} md={2} className={"full-height"}>
                <ChannelList channels={channels} setChannels={setChannels} selectedChannel={selectedChannel}
                             setSelectedChannel={setSelectedChannel}/>
            </Grid>)}
            <Grid item xs={12} md={6} className={"full-height"}
                  sx={{borderLeft: "2px dotted black", borderRight: "2px dotted black"}}>
                <MessageList messages={messages} setMessages={setMessages} channelId={selectedChannel}
                             prepareActiveThread={prepareActiveThread} openFullscreenImage={openFullscreenImage}
                             selectedMessageId={parentMessageThread} channelName={channelName}/>
            </Grid>
            {mobileScreen ? (<Drawer
                anchor="right"
                open={activeThread !== null}
                onClose={() => prepareActiveThread(null, null)}
            >
                <ThreadView activeThread={activeThread} prepareActiveThread={prepareActiveThread}
                            channelId={selectedChannel} openFullscreenImage={openFullscreenImage}
                            parentMessageId={parentMessageThread}/>
            </Drawer>) : (<Grid item xs={0} md={4} className={"full-height"}>
                <ThreadView activeThread={activeThread} prepareActiveThread={prepareActiveThread}
                            channelId={selectedChannel} openFullscreenImage={openFullscreenImage}
                            parentMessageId={parentMessageThread}/>
            </Grid>)}
        </Grid>
        <Modal
            open={!!fullscreenImage}
            onClose={closeFullscreenImage}
            aria-labelledby="fullscreen-image-modal"
            aria-describedby="fullscreen-image-description"
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} zzz
        >
            <Box sx={{outline: 0}}>
                <IconButton
                    aria-label="close"
                    onClick={closeFullscreenImage}
                    sx={{position: 'absolute', top: 0, right: 0, color: 'white', zIndex: 1}}
                >
                    <CloseIcon/>
                </IconButton>
                <img
                    src={fullscreenImage}
                    alt="Fullscreen Image"
                    style={{maxWidth: '90vw', maxHeight: '90vh', objectFit: 'contain'}}
                />
            </Box>
        </Modal>
    </div>);
};

const mapStateToProps = (state) => ({
    accessToken: Cookies.get('access_token'),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelComponent);


