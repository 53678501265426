import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import Cookies from 'js-cookie';

const { REACT_APP_BACKEND_API_URL } = process.env;

const FolderDropdown = ({ onFolderSelect, folder_id }) => {
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);

    useEffect(() => {
        const fetchFolders = async () => {
            try {
                const accessToken = Cookies.get('access_token');
                let url = `${REACT_APP_BACKEND_API_URL}/api/folders_list/`;
                if (folder_id !== null) {
                    url += `?folder_id=${folder_id}`;
                }
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                setFolders(response.data.folders || []);
            } catch (error) {
                console.error('Error fetching folders:', error);
            }
        };

        fetchFolders();
    }, []);

    const handleFolderSelect = (folder) => {
        setSelectedFolder(folder);
        if (onFolderSelect) {
            onFolderSelect(folder.id);
        }
    };

    return (
        <div>
            <InputLabel id="folder-select-label">Select a Folder</InputLabel>
            <Autocomplete
                id="folder-select"
                options={folders}
                getOptionLabel={(folder) => folder.name}
                value={selectedFolder}
                onChange={(event, folder) => handleFolderSelect(folder)}
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                        <input
                            type="text"
                            {...params.inputProps}
                            placeholder="Search Folder"
                            style={{
                                width: '100%',
                                padding: '8px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                marginTop: '10px',
                            }}
                        />
                    </div>
                )}
            />
        </div>
    );
};

export default FolderDropdown;
