import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {logoutSuccess} from "../redux/actions/login.action";
import {Box, Container, Menu, MenuItem} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';

const AppBarComponent = () => {
    const user = useSelector((state) => state.auth.user); // Access auth state from Redux store

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const dispatch = useDispatch();

    const history = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        Cookies.remove("access_token");
        dispatch(logoutSuccess());
        history('/login');

    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    return (
        <AppBar position="static" style={{backgroundColor: "black", maxHeight: 60, height: 60}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography variant="h6" sx={{display: {xs: 'none', md: 'flex'}, flexGrow: 1}}>
                        Task Logger
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        {user?.username && <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {user?.username && <MenuItem
                                component={Link}
                                to="/dashboard"
                                onClick={handleCloseNavMenu}
                            >
                                <Typography textAlign="center">Dashboard</Typography>
                            </MenuItem>}
                            {user?.username && <MenuItem
                                component={Link}
                                to="/search"
                                onClick={handleCloseNavMenu}
                            >
                                <Typography textAlign="center">Search</Typography>
                            </MenuItem>}
                            {user?.username && <MenuItem
                                component={Link}
                                to="/channels"
                                onClick={handleCloseNavMenu}
                            >
                                <Typography textAlign="center">Channels</Typography>
                            </MenuItem>}
                        </Menu>}
                    </Box>
                    <Typography variant="h6" sx={{display: {xs: 'flex', md: 'none'}, flexGrow: 1}}>
                        Task Logger
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {user?.username && <Button
                            component={Link}
                            to="/"
                            variant="contained"
                            style={{
                                backgroundColor: "black",
                                color: "white",
                                marginLeft: 20

                            }}
                        >
                            Home
                        </Button>}
                        {user?.username && <Button
                            component={Link}
                            to="/search"
                            variant="contained"
                            style={{
                                backgroundColor: "black",
                                color: "white",
                                marginLeft: 0
                            }}
                        >
                            Search
                        </Button>}
                        {user?.username && <Button
                            component={Link}
                            to="/channels"
                            variant="contained"
                            style={{
                                backgroundColor: "black",
                                color: "white",
                                marginLeft: 0
                            }}
                        >
                            Channels
                        </Button>}
                    </Box>

                    {user?.username ? (
                        <>
                            <Avatar alt={user.first_name} src={user.picture} sx={{marginRight: '8px'}}/>
                            <Typography variant="body1" sx={{marginRight: '16px', display: {xs: 'none', md: 'flex'}}}>
                                {user.first_name} {user.last_name}
                            </Typography>
                            <IconButton
                                onClick={handleLogout}
                                color={"inherit"}
                            >
                                <LogoutIcon/>
                            </IconButton>
                        </>
                    ) : <></>}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default AppBarComponent;
