import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Cookies from 'js-cookie';
import axios from 'axios';
import toast from 'react-hot-toast';
import Grid from '@mui/material/Grid';
import Folder from '../../components/folder';
import {Chip, CircularProgress, Divider, Button, ButtonGroup, Box} from '@mui/material';
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import {connect, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const {REACT_APP_BACKEND_API_URL} = process.env;

const SearchComponent = (access_token) => {
    const user = useSelector((state) => state.auth.user); // Access auth state from Redux store
    const history = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingNewData, setIsFetchingNewData] = useState(false);
    const [folders, setFolders] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [links, setLinks] = useState([]);
    const [tags, setTags] = useState([]);
    const [users, setUsers] = useState([]);
    const [value, setValue] = useState('');
    const [assignee, setAssigneeId] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const [teams, setTeams] = useState([]);
    const [assignees, setAssignees] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [parents, setParents] = useState(null);
    const [tagMethodOr, setTagMethodOr] = useState(true);
    const handleChipClick = (tag) => {
        const isSelected = selectedTags.includes(tag.id); // Check if tag is already selected
        if (isSelected) {
            // Tag is already selected, remove it
            setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag.id));
        } else {
            // Tag is not selected, add it
            setSelectedTags([...selectedTags, tag.id]);
        }
    };

    useEffect(() => {
        if (!access_token) {
            history('/');
        }
    }, [access_token]);

    const updateFolderNameHandler = (id, name, status = null, is_starred= null, tags = null) => {
        const updatedFolders = folders.map(folder => {
            if (folder.id === id) {
                if (status) {
                    return {...folder, status: status}
                }
                if (is_starred !== null) {
                    return {...folder, is_starred: is_starred}
                }
                if (tags !== null) {
                    return {...folder, tags: tags}
                }
                return {...folder, name: name}
            }
            return folder;
        })
        if (is_starred != null) {
            updatedFolders.sort((a, b) => {
                return b.is_starred - a.is_starred;
            });
        }
        setFolders(updatedFolders);
    }

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const accessToken = Cookies.get('access_token');
                const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/users_and_tags`, {
                    headers: {
                        Authorization: `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                const userData = response.data;
                setUsers(userData.users || []);
                setTags(userData.tags || []);
            } catch (error) {
                toast.error('Error fetching user list.');
            }
        };
        const fetchTeams = async () => {
            try {
                const accessToken = Cookies.get('access_token');
                const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/get_teams`, {
                    headers: {
                        Authorization: `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                const userData = response.data;
                setTeams(userData.teams || []);
            } catch (error) {
                toast.error('Error fetching user list.');
            }
        }
        const fetchFoldersWithParents = async () => {
            try {
                const accessToken = Cookies.get('access_token');
                const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/folders/parents/`, {
                    headers: {
                        Authorization: `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                setParents(response.data.folder_parents);
            } catch (error) {
                toast.error('Error fetching folders with parents.');
            }
        }

        // Call the fetchUsers function when the component mounts
        if (access_token) {
            fetchUsers();
            fetchTeams();
            // fetchFoldersWithParents()
        }
    }, []); // Empty dependency array ensures this effect runs only once on mount

    useEffect(() => {
        if (access_token) {
            handleSearch();
        }
    }, [assignee, selectedTags, assignees]);
    const handleSearch = async () => {



        const query = value;

        if (assignee === null && query === "" && selectedTeam === "") {
            return
        }

        setIsFetchingNewData(true);
        try {
            const accessToken = Cookies.get('access_token');
            let url = `${REACT_APP_BACKEND_API_URL}/api/search?query=${query}`
            if (assignee) {
                url = url + `&assigned_to=${assignee}`
            }
            if (assignees !== null) {
                url = url + `&team=${selectedTeam}`
            }
            if (selectedTags.length > 0) {
                url = `${url}&tags=${selectedTags.join(",")}`;
            }

            if (!tagMethodOr){
                url = `${url}&tag_method=and`;
            }

            const response = await axios.get(url, {
                headers: {
                    Authorization: `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const searchData = response.data;

            const folders = searchData.folders;
            const updatedFolders = folders.map(folder => {
                let status = folder.status;
                const custom_status_order = ["Taking Longer", "Doing", "Doing-in-bg", "Paused", "TODO", "Block-on-asm",
                           "Blocked-on-purchase", "Blocked-on-discussion", "Blocked", "Done"]
                if (folder.status.length <= 2) {
                    status = custom_status_order[parseInt(status, 10) - 1];
                }
                return {
                    ...folder,
                    status: status
                }
            })

            setFolders(updatedFolders || []);
            setTasks(searchData.tasks || []);
            setLinks(searchData.links || []);
            setIsFetchingNewData(false);
        } catch (error) {
            toast.error('Error fetching data.');
        }
    };

    const onFolderDelete = (id) => {
        const updated_folders = folders.filter(folder => folder.id !== id)
        setFolders(updated_folders)
    }

    const findValueById = (idToFind) => {
        if (parents?.hasOwnProperty(idToFind.toString())) {
            return parents[idToFind];
        }
        return null;
    };

    return (<>
        {
            isLoading ?
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh'}}>
                    <CircularProgress/>
                </div> : <>
                <div style={{width: '100%', textAlign: 'center', marginTop: '30px', marginLeft: "25%"}}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <TextField
                                style={{
                                    marginTop: "10px"
                                }}
                                placeholder="Search... (Press Enter to search)"
                                variant="outlined"
                                onChange={event => setValue(event.target.value.trim())}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                     handleSearch()
                                    }
                                }}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <IconButton type="submit" aria-label="search">
                                            <SearchIcon/>
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Autocomplete
                                options={users}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setAssigneeId(newValue.id);
                                    } else {
                                        setAssigneeId(null)
                                    }
                                }}

                                getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                renderOption={(props, option) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: 5,
                                        }}
                                        {...props}
                                    >
                                        <img
                                            src={option.picture}
                                            alt="Assignee's Picture"
                                            style={{
                                                height: 30,
                                                width: 30,
                                                borderRadius: '50%',
                                                marginRight: 10,
                                            }}
                                        />
                                        <Typography
                                            variant="body1">{`${option.first_name} ${option.last_name}`}</Typography>
                                    </div>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Search People"
                                        variant="outlined"
                                        fullWidth
                                        style={{marginTop: '10px'}}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Autocomplete
                                options={teams}
                                onChange={(event, newValue) => {
                                    if (newValue?.team_name !== null) {
                                        setSelectedTeam(newValue?.team_name);
                                        if (newValue?.members && newValue.members.length > 0) {
                                            setAssignees(newValue.members.map(obj => obj.id))
                                        } else {
                                            setAssignees(null)
                                        }
                                    } else {
                                        setSelectedTeam('');
                                    }
                                }}

                                getOptionLabel={(option) => option['team_name']}
                                renderOption={(props, option) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: 5,
                                        }}
                                        {...props}
                                    >
                                        <Typography
                                            variant="body1">{`${option.team_name}`}</Typography>
                                    </div>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Search Team"
                                        variant="outlined"
                                        fullWidth
                                        style={{marginTop: '10px'}}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </div>

                <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                        <ButtonGroup style={{maxHeight:24}} variant="contained" aria-label="Basic button group">
                            <Button size='small' onClick={() => setTagMethodOr(true)} color={tagMethodOr ? 'primary': 'inherit'}>OR</Button>
                            <Button size={'small'} onClick={() => setTagMethodOr(false)} color = {tagMethodOr ? 'inherit': 'primary'} >AND</Button>
                        </ButtonGroup>
                    </div>


                <div style={{textAlign: "center", margin: 10}}>
                    {tags.map((tag, index) => (
                        <Chip
                            key={index}
                            label={tag.name}
                            clickable
                            onClick={() => handleChipClick(tag)}
                            color={selectedTags.includes(tag.id) ? "primary" : "default"}
                            style={{marginRight: '5px', marginBottom: '5px'}}
                        />
                    ))}
                </div>
                </div>

                {isFetchingNewData ? <div style={{display: "flex", width: "100vw", justifyContent: "center"}}><CircularProgress /></div> : <div style={{width: '100%', marginLeft: '10%'}}>
                    <Grid container style={{marginTop: '20px', width: '80%'}}>

                        <Grid item xs={12}>
                            <div style={{marginBottom: '20px'}}>
                                <h2>Folders</h2>
                                <Divider style={{marginBottom: 10}}/>
                                {folders.length > 0 ? (
                                    <div>
                                        {folders.map((folder) => (
                                            <Folder
                                                on_delete={onFolderDelete}
                                                updateNameHandler={updateFolderNameHandler}
                                                key={folder.id}
                                                name={folder.name}
                                                id={folder.id}
                                                depth={-1}
                                                data={folder}
                                                selected_folders={[]}
                                                parents={findValueById(folder.id)}
                                                is_archived={folder.is_archived}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <p>No folders found.</p>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div>}
            </>}
    </>
    );
};

const mapStateToProps = (state) => ({
    accessToken: Cookies.get('access_token'),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);


