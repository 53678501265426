import React from 'react';
import {
    Avatar,
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import ReactAudioPlayer from 'react-audio-player';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ChannelToComment from "./channelToComment";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TaskIcon from '@mui/icons-material/Task';
import LinkifyTypography from "./LinkifyTypography";
import ReplyIcon from '@mui/icons-material/Reply';
import {downloadFile} from "../utils/utils";

const MessageCard = ({
                         message,
                         isThread,
                         prepareActiveThread,
                         updateMessageStatus,
                         openFullscreenImage,
                         user,
                         selectedMessageId
                     }) => {
    return (<Card
        key={message.id}
        sx={{
            maxWidth: 600,
            marginBottom: 2,
            alignSelf: message.author.username === user?.username ? 'flex-end' : 'flex-start',
            backgroundColor: message.id === selectedMessageId ? '#25D36666' : 'unset'
        }}
    >
        <CardHeader
            avatar={<Avatar src={message.author.picture} alt={message.author.username}/>}
            title={`${message.author.first_name} ${message.author.last_name}`}
            subheader={new Date(message.created_at).toLocaleString()}
        />
        {message.media_type === 'image' && (<CardMedia
            component="img"
            src={message.media_url}
            alt="Uploaded Image"
            sx={{height: 250, cursor: 'pointer'}}
            onClick={() => openFullscreenImage(message.media_url)}
        />)}
        {message.media_type === 'video' && (<CardMedia
            component="video"
            controls
            src={message.media_url}
            title="Uploaded Video"
            sx={{height: 250}}
        />)}
        {message.media_type === 'audio' && (<ReactAudioPlayer
            src={message.media_url}
            controls
        />)}
        <CardContent>
            <LinkifyTypography variant="body1">{message.message}</LinkifyTypography>
        </CardContent>
        <CardActions>
            <Stack direction="row" justifyContent="space-between" width="100%">
                <Stack direction="row">
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        {message.action_taken ? <TaskIcon sx={{color: "green"}}/> :
                            <PendingActionsIcon sx={{color: "orange"}}/>}
                    </Box>
                    <ChannelToComment channel_message={message} msgModifyCb={updateMessageStatus}/>
                    {message.media_url && message.media_type === 'other' ?
                        <IconButton size="small" onClick={() => downloadFile(message.media_url)}>
                            <CloudDownloadIcon/>
                        </IconButton> : null}

                </Stack>
                {!isThread && <Stack direction="row" alignItems="center">
                    {message.reply_count > 0 && <Typography sx={{
                        color: "blue", textDecoration: "underline", cursor: "alias"
                    }} onClick={() => prepareActiveThread(message.root_thread, message.id)}>
                        {message.reply_count} Replies
                    </Typography>}
                    <Tooltip title="Reply in Thread">
                        <IconButton
                            onClick={() => prepareActiveThread(message.root_thread !== null ? message.root_thread : -1, message.id)}>
                            <ReplyIcon/>
                        </IconButton>
                    </Tooltip>
                </Stack>}
            </Stack>
        </CardActions>
    </Card>);
};

export default MessageCard;
