import React, {useEffect, useRef, useState} from 'react';
import {Box, Container, Divider, Stack, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import axios from 'axios';
import Cookies from 'js-cookie';
import InfiniteScroll from "react-infinite-scroll-component";
import MessageCard from "./MessageCard";
import CreateMessage from "./addMessageToChannel";

const {REACT_APP_BACKEND_API_URL} = process.env;

const MessageList = ({
                         messages,
                         setMessages,
                         channelId,
                         prepareActiveThread,
                         openFullscreenImage,
                         selectedMessageId,
                         channelName
                     }) => {
    const {user} = useSelector((state) => state.auth);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);

    useEffect(() => {
        setPage(1);
        setMessages([]);
        setHasMore(true);
    }, [channelId]);

    useEffect(() => {
        const fetchMessages = async (page) => {
            if (channelId) {
                try {
                    const accessToken = Cookies.get('access_token');
                    const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/channels/${channelId}/messages`, {
                        headers: {
                            Authorization: `Token ${accessToken}`, 'Content-Type': 'application/json',
                        }, params: {page}
                    });
                    if (response.data && response.data.results) {
                        setMessages((prevMessages) => [...prevMessages, ...response.data.results]);
                        if (response.data?.next === null) {
                            setHasMore(false);
                        }
                    }
                } catch (error) {
                    console.error('Failed to fetch messages:', error);
                }
            }
        };

        fetchMessages(page);
    }, [page, channelId]);

    const triggerFetchMoreData = () => {
        if (hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
    }

    const updateMessageStatus = (messageId) => {
        const messageIndex = messages.findIndex(message => message.id === messageId);
        if (messageIndex !== -1) {
            const updatedMessages = [...messages];
            updatedMessages[messageIndex] = {
                ...updatedMessages[messageIndex], action_taken: true,
            };
            setMessages(updatedMessages);
        }
    }

    const heightContainer = window?.innerHeight - 190;


    return (<Stack className={"full-parent"}>
            <Container className={"full-parent"} sx={{paddingTop: 2, height: 'calc(100% - 50px)'}}>
                <Typography variant="h5" gutterBottom align="center">
                    {channelName}
                </Typography>
                <Divider style={{marginBottom: 10}}/>
                {channelId ? <InfiniteScroll
                    dataLength={messages.length}
                    next={triggerFetchMoreData}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    height={heightContainer + "px"}
                    endMessage={<p style={{textAlign: "center"}}>
                        <b>Yay! You have seen it all</b>
                    </p>}
                    style={{width: '100%'}}
                >
                    <Box
                        ref={containerRef}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: 'fit-content',
                            minWidth: '100%',
                            width: '100%',
                            overflow: 'auto'
                        }}
                    >
                        {messages.map((message) => (<MessageCard message={message}
                                                                 isThread={false}
                                                                 prepareActiveThread={prepareActiveThread}
                                                                 updateMessageStatus={updateMessageStatus}
                                                                 openFullscreenImage={openFullscreenImage}
                                                                 user={user}
                                                                 selectedMessageId={selectedMessageId}
                        />))}
                    </Box>
                </InfiniteScroll> : <Typography>Select a channel to load messages</Typography>}
            </Container>
            <CreateMessage messages={messages} setMessages={setMessages} channelId={channelId} threadId={null}
                           parentMessageId={null}/>
        </Stack>
    )
        ;
};

export default MessageList;
