import Avatar from "@mui/material/Avatar";
import {stringToColor, truncateStringWithShortcut} from "../utils/utils";
import React, {useEffect} from "react";
import FolderSharedIcon from "@mui/icons-material/FolderShared";

const Shortcut = ({ data, depth }) => {
    const [href, setHref] = React.useState("");

    useEffect(() => {
        if (depth !== -1) {
            setHref(`${window.location.origin}/folder/${data.original_item}`)
        }
    }, []);

    return (
        <>
            <div className={"folder-container"}>
                <a href={href} rel="noopener noreferrer"
                   style={{textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', width: "100%"}}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start'
                    }}>
                        <div style={{
                            flex: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: 390
                        }}>
                            <FolderSharedIcon style={{color: '#d5406c', marginTop: 15, marginRight: 10}}/>
                            <span>{truncateStringWithShortcut(data.name, 45)}</span>
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'end',
                        justifyContent: 'flex-end'
                    }}>
                        {data.assignee && (
                            <Avatar
                                alt="No Image"
                                style={{
                                    height: 30,
                                    width: 30,
                                    borderRadius: '50%',
                                    fontSize: "14px",
                                    backgroundColor: stringToColor(data.assignee.first_name + data.assignee.last_name),
                                    color: 'white',
                                    marginBottom: '5px'
                                }}
                            >
                                {data.assignee.first_name.charAt(0) + data.assignee.last_name.charAt(0)}
                            </Avatar>
                        )}
                    </div>
                </a>
            </div>
        </>
    )
}

export default Shortcut;