import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {
    addFolder,
    addLink,
    fetchFolders,
} from "../redux/actions/folders.action";
import Cookies from "js-cookie";
import Folder from "./folder";
import {Checkbox, FormControlLabel, FormGroup, Stack, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import Button from "@mui/material/Button";
import Link from "./link";
import "../assets/style.css";
import toast from "react-hot-toast";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Shortcut from "./shortcut";
import {configureKeyboardNavigation} from "../redux/actions/global.action";
import {updateUserSettings} from "../redux/actions/login.action";

const {REACT_APP_BACKEND_API_URL} = process.env;

const FoldersComponent = ({accessToken, depth, folders, selected_folders, addFolder, links, shortcuts, addLink, focusedIndex, handleKeyDown}) => {
    const user_settings = useSelector((state) => state.auth.user_settings);

    const [newTaskOrLinkName, setNewTaskOrLinkName] = useState('');
    const [isAddingFolder, setIsAddingFolder] = useState(false);
    const [isAddingLinkOrTask, setIsAddingLinkOrTask] = useState(false);
    const [isAddingSheet, setIsAddingSheet] = useState(false);
    const [isAddingDoc, setIsAddingDoc] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [newName, setNewName] = React.useState('');
    const initialArchive = Cookies.get("archiveStatus") === undefined ? 'excludeArchive' : Cookies.get("archiveStatus");
    const [value, setValue] = useState(initialArchive);
    const [children, setChildren] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isMinimalView = user_settings?.minimal_mode;

    // useEffect(() => {
    //     const fetchFoldersWithChildren = async () => {
    //         try {
    //             const accessToken = Cookies.get('access_token');
    //             const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/folders/children/`, {
    //                 headers: {
    //                     Authorization: `Token ${accessToken}`,
    //                     'Content-Type': 'application/json',
    //                 },
    //             });
    //             setChildren(response.data.children_count);
    //         } catch (error) {
    //             toast.error('Error fetching folders with children.');
    //         }
    //     }
    //     if (accessToken) {
    //         fetchFoldersWithChildren()
    //     }
    // }, []);

    useEffect(() => {
        if (isAddingFolder || isAddingLinkOrTask || isAddingDoc) {
            dispatch(configureKeyboardNavigation(false))
        } else {
            dispatch(configureKeyboardNavigation(true))
        }
    }, [isAddingDoc, isAddingFolder, isAddingLinkOrTask]);

    const handleArchiveChange = (event) => {
        const val = event.target.value
        if (val !== null) {
            Cookies.set("archiveStatus", val);
            setValue(val);
            navigate('/');
        }
        if (accessToken && val !== null) {
            dispatch(fetchFolders(true));
        }
    };

    const handleAddLinkOrTaskClick = () => {
        setIsAddingLinkOrTask(true);
    };

    const handleAddSheet = () => {
        setIsAddingSheet(true);
    };

    const handleAddDoc = () => {
        setIsAddingDoc(true);
    };

    const handleTaskOrLinkChange = (event) => {
        setNewTaskOrLinkName(event.target.value);
    };

    const isURL = (str) => {
        try {
            new URL(str);
            return true;
        } catch (error) {
            return false;
        }
    }

    const handleCreateNewSheet = async (name, is_sheet) => {
        try {
            const accessToken = Cookies.get('access_token');
            const response = await axios.post(
                `${REACT_APP_BACKEND_API_URL}/api/google/create_new_document/`,
                {
                    name: name,
                    is_sheet: is_sheet
                },
                {
                    headers: {
                        Authorization: `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            const sheetId = response.data.id;
            if (is_sheet) {
                return `https://docs.google.com/spreadsheets/d/${sheetId}`;
            } else {
                return `https://docs.google.com/document/d/${sheetId}`;
            }
        } catch (error) {
            console.log(error)
            toast.error("Error creating sheets");
        }
    }

    const handleCreateLinkOrTask = (event) => {

        if (event.key === 'Enter' && newTaskOrLinkName.trim() !== '') {

            if (depth > 1) {
                const parts = newTaskOrLinkName.split(",")
                if (isAddingSheet || isAddingDoc) {
                    setIsLoading(true)
                    handleCreateNewSheet(newTaskOrLinkName, isAddingSheet).then((url) => {
                        addLink(url, newTaskOrLinkName, depth, selected_folders[depth - 2]);
                        window.open(url, '_blank');
                        setIsLoading(false)
                    }).catch(() => {
                        setIsLoading(false)
                    })
                }
                else if (isURL(parts[0])) {
                    if (parts.length >= 1) {
                        addLink(parts[0], parts[1], depth, selected_folders[depth - 2]);
                    } else {
                        addLink(parts[0], null, depth, selected_folders[depth - 2]);
                    }
                } else {
                    addFolder(newTaskOrLinkName, depth, selected_folders[depth - 2]);
                }
            } else {
                const parts = newTaskOrLinkName.split(",")
                if (isAddingSheet || isAddingDoc) {
                    setIsLoading(true)
                    handleCreateNewSheet(newTaskOrLinkName, isAddingSheet).then((url) => {
                        addLink(url, newTaskOrLinkName, depth, null);
                        window.open(url, '_blank');setIsLoading(false)
                    }).catch(() => {
                        setIsLoading(false)
                    })
                }
                else if (isURL(parts[0])) {
                    if (parts.length >= 1) {
                        addLink(parts[0], parts[1], depth, null);
                    } else {
                        addLink(parts[0], null, depth, null);
                    }
                } else {
                    addFolder(newTaskOrLinkName, depth, null);
                }
            }
            setNewTaskOrLinkName('');
            setIsAddingLinkOrTask(false);
            setIsAddingSheet(false);
            setIsAddingDoc(false);
            // setNewName(newTaskOrLinkName);
        }
    };

    const handleMinimalViewChange = (event) => {
        dispatch(updateUserSettings({ minimal_mode: event.target.checked }));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '88vh' }}>
            {(isAddingLinkOrTask || isAddingSheet || isAddingDoc) ? (
                    <div style={{marginTop: 2, marginBottom: 2, display: "flex", justifyContent: "center"}}>
                        <TextField
                            size="small"
                            spellCheck={false}
                            autoComplete="off"
                            variant="outlined"
                            label={isAddingSheet ? "Sheet Name" : isAddingDoc ? "Doc Name" : "Folder Name or Link"}
                            value={newTaskOrLinkName}
                            onChange={handleTaskOrLinkChange}
                            onKeyPress={handleCreateLinkOrTask}
                            onBlur={() => {
                                setIsAddingLinkOrTask(false)
                                setIsAddingSheet(false)
                                setIsAddingDoc(false)
                            }}
                            style={{
                                width: "90%",
                            }}
                            autoFocus

                        />
                    </div>
                ) : loading ? <div>Creating...</div> : (
                    <Stack direction="row" justifyContent={"space-between"} paddingY={0.5} paddingX={1.5}>
                        <Button variant="contained" color="primary"
                                size="small"
                                onClick={handleAddLinkOrTaskClick}
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    color: "#000000",
                                    fontWeight: "bold",
                                    padding: 1,
                                    border: "1px solid",
                                    borderRadius: "4px",
                                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                                    marginBottom: 1,
                                    width: "30%",
                                    marginTop: 1
                                }}
                        >
                            + Folder
                        </Button>
                        <Button variant="contained" color="primary"
                                size="small"
                                onClick={handleAddSheet}
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    color: "#000000",
                                    fontWeight: "bold",
                                    padding: 1,
                                    border: "1px solid",
                                    borderRadius: "4px",
                                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                                    marginBottom: 1,
                                    width: "30%",
                                    marginTop: 1
                                }}
                        >
                            + Sheet
                        </Button>
                        <Button variant="contained" color="primary"
                                size="small"
                                onClick={handleAddDoc}
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    color: "#000000",
                                    fontWeight: "bold",
                                    padding: 1,
                                    border: "1px solid",
                                    borderRadius: "4px",
                                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                                    marginBottom: 1,
                                    width: "30%",
                                    marginTop: 1
                                }}
                        >
                            + Doc
                        </Button>
                    </Stack>
                )}
            <div style={{
                flex: 1,
                width: 450,
                padding: 10,
                overflowY: 'auto',
                overflowX: 'hidden'
            }} className={"scrollbar-width"}>
                {shortcuts[depth]?.map(shortcut => {
                    return <Shortcut key={shortcut.id} data={shortcut} depth={depth}/>
                })}
                {links[depth]?.map(link => {
                    return <Link href={link.link} id={link.id} selected_folders={selected_folders} name={link.name}
                                 key={link.id} data={link} depth={depth} handleKeyDown={handleKeyDown} is_archived={link.is_archived}/>
                })}
                {folders[depth]?.map(((folder, index) => {
                    return <Folder name={folder.name} key={folder.id} id={folder.id} depth={depth} data={folder}
                                   focusedIndex={focusedIndex}
                                   f={`${depth}_${index}`}
                                   selected_folders={selected_folders} is_archived={folder.is_archived}
                                   children={children?.[folder.id] || {}}
                                   handleKeyDown={handleKeyDown}
                                   agg_data={folder.aggregator_data}
                    />
                }))}

            </div>
            <div className={"footer"}>
                <Stack direction="row" justifyContent="space-between">
                    <ToggleButtonGroup color="primary" value={value} exclusive onChange={handleArchiveChange}>
                        <ToggleButton value="includeArchive" style={{
                            fontSize: 11,
                            textTransform: 'capitalize',
                            color: value === 'includeArchive' ? 'aqua' : '#fff',
                            borderColor: value === 'includeArchive' ? 'aqua' : '#fff'
                        }}>
                            All
                        </ToggleButton>
                        <ToggleButton value="excludeArchive" style={{
                            fontSize: 11,
                            textTransform: 'capitalize',
                            color: value === 'excludeArchive' ? 'aqua' : '#fff',
                            borderColor: value === 'excludeArchive' ? 'aqua' : '#fff'
                        }}>
                            Exclude Archive
                        </ToggleButton>
                        <ToggleButton value="onlyArchive" style={{
                            fontSize: 11,
                            textTransform: 'capitalize',
                            color: value === 'onlyArchive' ? 'aqua' : '#fff',
                            borderColor: value === 'onlyArchive' ? 'aqua' : '#fff'
                        }}>
                            Only Archive
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <FormGroup sx={{backgroundColor: "#868484"}}>
                        <FormControlLabel control={<Checkbox checked={isMinimalView} color={"warning"} onChange={handleMinimalViewChange} />} label="Minimal View" />
                    </FormGroup>
                </Stack>

            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    accessToken: Cookies.get('access_token'),
    shortcuts: state.folders.shortcuts,
    folders: state.folders.folders,
    links: state.folders.links,
    tasks: state.folders.tasks,
    selected_folders: state.folders.selected_folders
});

const mapDispatchToProps = {
    fetchFolders,
    addFolder,
    addLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(FoldersComponent);
