import React, {useState} from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton} from '@mui/material';
import FolderDropdown from "./FolderDropdown";
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import Cookies from "js-cookie";
import axios from "axios";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {fetchAllFolderdetails, fetchFolders} from "../redux/actions/folders.action";
import {connect} from "react-redux";
import Tooltip from "@mui/material/Tooltip";

const {REACT_APP_BACKEND_API_URL} = process.env;
const MoveFolderModal = ({folder_id, fetchAllFolderdetails}) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [selectedFolderId, setSelectedFolderId] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedFolderId(''); // Reset selected folder when closing modal
    };

    const handleFolderSelect = (folderId) => {
        setSelectedFolderId(folderId);
    };

    const handleMoveFolder = async () => {
        if (selectedFolderId) {
            try {
                const accessToken = Cookies.get('access_token');
                let params = ""
                if (selectedFolderId != -1) {
                    params = `folder_id=${selectedFolderId}`
                }
                const url = `${REACT_APP_BACKEND_API_URL}/api/move_item/${folder_id}?${params}`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                toast.success("Folder Moved Successfully.")
                navigate(`/folder/${folder_id}`);
                fetchAllFolderdetails(folder_id)
                // // Reload the page after navigation
                // window.location.reload();
            } catch (error) {
                toast.error("Error Moving Folder");
            }

            setOpen(false);
            setSelectedFolderId('');
        }
    };

    return (
        <>
            <Tooltip title={"Move"}>
                <IconButton onClick={handleOpen} style={{
                    float: "right",
                    top: '8px',
                    right: '8px',
                }}>
                    <DriveFileMoveIcon/>
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth sx={{ '& .MuiDialog-paper': { width: '80%' } }}>
                <DialogTitle>Move Item</DialogTitle>
                <DialogContent>
                    <FolderDropdown onFolderSelect={handleFolderSelect} folder_id={folder_id}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleMoveFolder} disabled={!selectedFolderId} color="primary">
                        Move
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    fetchAllFolderdetails
};
export default connect(mapStateToProps, mapDispatchToProps)(MoveFolderModal);
