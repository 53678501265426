import {fetchAllFolderdetails} from "../redux/actions/folders.action";
import {connect} from "react-redux";
import ShortcutIcon from '@mui/icons-material/Shortcut';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import React, {useState} from "react";
import FolderDropdown from "./FolderDropdown";
import Cookies from "js-cookie";
import axios from "axios";
import toast from "react-hot-toast";
import Tooltip from "@mui/material/Tooltip";

const {REACT_APP_BACKEND_API_URL} = process.env;

const ShortcutDialog = ({ folder_id, fetchAllFolderdetails }) => {
    const [open, setOpen] = useState(false);
    const [selectedFolderId, setSelectedFolderId] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedFolderId('');
    };

    const handleFolderSelect = (folderId) => {
        setSelectedFolderId(folderId);
    };

    const createShortcutFolder = async () => {
        try {
            const accessToken = Cookies.get("access_token");
            const url = `${REACT_APP_BACKEND_API_URL}/api/create_folder_shortcut/?original_folder_id=${folder_id}&destination_folder_id=${selectedFolderId}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            toast.success("Folder Shortcut created Successfully.")
            fetchAllFolderdetails(folder_id)
        } catch (error) {
            toast.error("Error in Creating Shortcut.")
        }
        handleClose();
    }

    return (
        <>
            <Tooltip title={"Shortcut"}>
                <IconButton onClick={handleOpen} style={{float: "right", top: '8px', right: '8px'}}>
                    <ShortcutIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth sx={{ '& .MuiDialog-paper': { width: '80%' } }}>
                <DialogTitle>Create this folder as shortcut in folder</DialogTitle>
                <DialogContent>
                    <FolderDropdown onFolderSelect={handleFolderSelect} folder_id={folder_id}/>
                    <span style={{fontStyle: 'italic', fontSize: 12}}>
                        Note: If leaving empty creates shortcut at depth 1
                    </span>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={createShortcutFolder} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = {
    fetchAllFolderdetails
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortcutDialog);