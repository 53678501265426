import React, { useEffect, useState } from "react";
import withWidth, { isWidthDown } from "@mui/material/Hidden/withWidth";
import Home from "../Home";
import ChannelComponent from "../Home/channel";
import Cookies from 'js-cookie';

const HomeRoute = ({ width }) => {
    const [smallScreen, setSmallScreen] = useState(null);
    const [cookiesOk, setCookiesOk] = useState(false);

    useEffect(() => {
        const isSmallScreen = isWidthDown('sm', width);
        setSmallScreen(isSmallScreen);
    }, [width]);

    useEffect(() => {
        if (Cookies.get('archiveStatus') === null || Cookies.get('archiveStatus') === undefined) {
            Cookies.set('archiveStatus', 'excludeArchive')
            setTimeout(() => {
                setCookiesOk(true);
            }, 1000);
        } else {
            setCookiesOk(true);
        }
    }, []);

     if (!cookiesOk) {
        return <div>Waiting for cookies... If this takes long, please reload the page.</div>;
    }

    return smallScreen ? <ChannelComponent /> : <Home />;
};

export default withWidth()(HomeRoute);