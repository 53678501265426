import {
    ADD_FOLDER, ADD_LINK, DELETE_FOLDER, DELETE_LINK,
    FETCH_ALL_DATA_SUCCESS, FETCH_DATA_FAILURE, FETCH_DATA_SUCCESS, FETCH_WITH_ARCHIVE_STATUS,
    FOLDER_ASSIGNEE_SUCCESS, LINK_ASSIGNEE_SUCCESS, OPEN_DETAIL_PANE,
    RENAME_FOLDER_SUCCESS, UPDATE_ARCHIVE_STATUS, UPDATE_LINK_SUCCESS,
} from "../constants/folders.constant";
import Cookies from "js-cookie";
import shortcut from "../../components/shortcut";

const initialState = {
    folders: {},
    depth: 0,
    error: null,
    selected_folders: [],
    links: {},
    shortcuts: {},
    open_folder: null
};
const custom_status_order = ["Taking Longer", "Doing", "Doing-in-bg", "Paused", "TODO", "Block-on-asm",
                           "Blocked-on-purchase", "Blocked-on-discussion", "Blocked", "Done"];

const getStatusForSorting = (status, custom_status_order) => {
    if (status.length <= 2) {
        const index = parseInt(status, 10) - 1;
        return custom_status_order[index];
    }
    return status;
};

const folderReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WITH_ARCHIVE_STATUS:
            return {
                ...state,
                folders: {
                    [action.payload.depth]: action.payload.folders.folders
                },
                links: {
                    [action.payload.depth]: action.payload.folders.links
                },
                shortcuts: {
                    [action.payload.depth]: action.payload.folders.shortcuts
                }
            }

        case FETCH_DATA_SUCCESS:
            if (action.payload.child) {
                const folders = {
                    ...state.folders,
                    [action.payload.depth]: action.payload.folders.folders
                }

                const links = {
                    ...state.links,
                    [action.payload.depth]: action.payload.folders.links
                }

                const shortcuts = {
                    ...state.shortcuts,
                    [action.payload.depth]: action.payload.folders.shortcuts
                }

                const filteredFolders = Object.keys(folders).reduce((acc, key) => {
                    const folderDepth = parseInt(key, 10);
                    if (folderDepth <= action.payload.depth) {
                        acc[key] = folders[key];
                    }
                    return acc;
                }, {});

                const filteredLinks = Object.keys(links).reduce((acc, key) => {
                    const folderDepth = parseInt(key, 10);
                    if (folderDepth <= action.payload.depth) {
                        acc[key] = links[key];
                    }
                    return acc;
                }, {});

                const filteredShortcuts = Object.keys(shortcuts).reduce((acc, key) => {
                    const folderDepth = parseInt(key, 10);
                    if (folderDepth <= action.payload.depth) {
                        acc[key] = shortcuts[key];
                    }
                    return acc;
                }, {});

                return {
                    ...state,
                    folders: filteredFolders,
                    links: filteredLinks,
                    shortcuts: filteredShortcuts,
                    selected_folders: [...state.selected_folders.slice(0, action.payload.depth - 2), action.payload.folder_id]
                }
            }
            return {
                ...state,
                folders: {
                    ...state.folders,
                    [action.payload.depth]: action.payload.folders.folders
                },
                links: {
                    ...state.links,
                    [action.payload.depth]: action.payload.folders.links
                },
                shortcuts: {
                    ...state.shortcuts,
                    [action.payload.depth]: action.payload.folders.shortcuts
                }
            };

        case FETCH_DATA_FAILURE:
            return {
                ...state,
                folders: {...state.folders},
                links: {...state.links},
                shortcuts: {...state.shortcuts},
                error: action.payload,
            };

        case FETCH_ALL_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload.data
            }

        case ADD_FOLDER:
            const new_folders_list = [action.payload.data, ...state.folders[action.payload.depth]];
            new_folders_list.sort((a, b) => {
                const statusA = getStatusForSorting(a.status, custom_status_order);
                const statusB = getStatusForSorting(b.status, custom_status_order);
                return custom_status_order.indexOf(statusA) - custom_status_order.indexOf(statusB);
            });
            return {
                ...state,
                folders: {
                    ...state.folders,
                    [action.payload.depth]:new_folders_list,
                }
            };

        case ADD_LINK:
            return {
                ...state,
                links: {
                    ...state.links,
                    [action.payload.depth]: [action.payload.data, ...state.links[action.payload.depth]],
                }
            };

        case RENAME_FOLDER_SUCCESS:
            const folder_data = action.payload.folder;
            const folders = state.folders[action.payload.depth];
            const updatedFolders = folders.map(folder => {
                if (folder.id === folder_data.id) {
                    return {...folder, name: folder_data.name, status: folder_data.status, tags: folder_data.tags,
                        is_starred: folder_data.is_starred, is_archived: folder_data.is_archived};
                }
                return folder;
            });
            let filterFolders;
            const archiveStatus = Cookies.get('archiveStatus');

            if (archiveStatus === 'excludeArchive' || archiveStatus === undefined) {
                filterFolders = updatedFolders.filter(folder => !folder.is_archived);
            } else if (archiveStatus === 'onlyArchive') {
                filterFolders = updatedFolders.filter(folder => folder.is_archived);
            } else {
                filterFolders = updatedFolders;
            }

            filterFolders.sort((a, b) => {
                const statusA = getStatusForSorting(a.status, custom_status_order);
                const statusB = getStatusForSorting(b.status, custom_status_order);
                return custom_status_order.indexOf(statusA) - custom_status_order.indexOf(statusB);
            });

            filterFolders.sort((a, b) => {
                if (a.is_starred === b.is_starred) {
                    return 0;
                } else if (a.is_starred) {
                    return -1;
                } else {
                    return 1;
                }
            });

            return {
                ...state,
                folders: {
                    ...state.folders,
                    [action.payload.depth]: filterFolders,
                }
            };

        case FOLDER_ASSIGNEE_SUCCESS:
            const folder_details = action.payload.folder;
            const updated_folders = state.folders[action.payload.depth].map(folder => {
                if (folder.id === folder_details.id) {
                    return {...folder, employees: folder_details.employees};
                }
                return folder;
            });

            return {
                ...state,
                folders: {
                    ...state.folders,
                    [action.payload.depth]: updated_folders,
                }
            };

        case UPDATE_LINK_SUCCESS:
            const link_data = action.payload.link;
            const links = state.links[action.payload.depth];
            const updatedLinks = links.map(link => {
                if (link.id === link_data.id) {
                    return {...link, link: link_data.link, name: link_data.name, tags: link_data.tags,
                        is_starred: link_data.is_starred, is_archived: link_data.is_archived};
                }
                return link;
            });
            updatedLinks.sort((a, b) => {
                if (a.is_starred && !b.is_starred) {
                    return -1;
                } else if (!a.is_starred && b.is_starred) {
                    return 1;
                } else {
                    return 0;
                }
            });

            return {
                ...state,
                links: {
                    ...state.links,
                    [action.payload.depth]: updatedLinks,
                }
            };

        case LINK_ASSIGNEE_SUCCESS:
            const link_details = action.payload.link;
            const updated_links = state.links[action.payload.depth].map(link => {
                if (link.id === link_details.id) {
                    return {...link, assignee: link_details.assignee};
                }
                return link;
            });

            return {
                ...state,
                links: {
                    ...state.links,
                    [action.payload.depth]: updated_links,
                }
            };

        case DELETE_LINK:
            const link_id = action.payload.link_id;
            const depth = action.payload.depth;
            const links_data = state.links[depth];
            const filteredLinks = links_data.filter(link => {
                return link.id !== link_id;
            });

            return {
                ...state,
                links: {
                    ...state.links,
                    [action.payload.depth]: filteredLinks,
                }
            };

        case UPDATE_ARCHIVE_STATUS:
            const status = action.payload.status;
            return {
                ...state,
                archive_status: status
            }

        case DELETE_FOLDER:
            const folders_data = {
                ...state.folders,
            }

            const linkData = {
                ...state.links,
            }

            const filteredFolders = Object.keys(folders_data).reduce((acc, key) => {
                const folderDepth = parseInt(key, 10);
                if (folderDepth < action.payload.depth) {
                    acc[key] = folders_data[key];
                } else if (folderDepth === action.payload.depth) {
                    acc[key] = folders_data[key].filter(folder => folder.id !== action.payload.folder_id);
                }
                return acc;
            }, {});

            const filteredLinksData = Object.keys(linkData).reduce((acc, key) => {
                const folderDepth = parseInt(key, 10);
                if (folderDepth <= action.payload.depth) {
                    acc[key] = linkData[key];
                }
                return acc;
            }, {});

            return {
                ...state,
                folders: filteredFolders,
                links: filteredLinksData,
                selected_folders: [...state.selected_folders.slice(0, action.payload.depth - 1)]
            }

        case OPEN_DETAIL_PANE:
            const folderId = parseInt(action.folder_id);
            return {
                ...state,
                open_folder: isNaN(folderId) ? action.folder_id : folderId
            }

        default:
            return state;
    }
};

export default folderReducer;
