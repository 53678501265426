import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoute = () => {
  const accessToken = Cookies.get('access_token');
  const isAuthenticated = accessToken !== undefined && accessToken !== null;
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" state={{ from: window.location.pathname + window.location.search }} />;
};

export default ProtectedRoute;
