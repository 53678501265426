import React, {useRef, useState} from 'react';
import {IconButton} from "@mui/material";
import 'react-quill/dist/quill.snow.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const UploadAttachment = ({onFileChange}) => {

    const [mediaFile, setMediaFile] = useState(null);
    const [mediaType, setMediaType] = useState('');
    const [isMediaSelected, setIsMediaSelected] = useState(false);

    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            let mediaType = 'other'
            setMediaFile(file);
            setIsMediaSelected(true);

            // Determine media type based on file type
            if (file.type.startsWith('video')) {
                mediaType = 'video'
            } else if (file.type.startsWith('image')) {
                mediaType = 'image'
            } else if (file.type.startsWith('audio')) {
                mediaType = 'audio'
            }
            setMediaType(mediaType);
            onFileChange(file, mediaType);
        }
    };

    const clearAttachment = () => {
        setMediaFile(null);
        setIsMediaSelected(false);
        setMediaType('');
    }


    return (
        <>
            {isMediaSelected ? <IconButton onClick={() => fileInputRef.current.click()}>
                    <AttachFileIcon sx={{bgcolor: "green", color: "white", borderRadius: "5px"}}/>
                </IconButton> :
                <IconButton onClick={() => fileInputRef.current.click()}>
                    <AttachFileIcon/>
                </IconButton>}
            <input
                type="file"
                accept="*"
                capture="camera microphone"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{display: 'none'}}
            />
        </>
    );
};

export default UploadAttachment;
