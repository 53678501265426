import {TOGGLE_KEYBOARD_NAVIGATION, UPDATE_ITEM_ID_TO_MOVE} from "../constants/global.constant";

export const configureKeyboardNavigation = (enable) => ({
    type: TOGGLE_KEYBOARD_NAVIGATION,
    payload: enable,
});

export const setItemIdToMove = (item_id) => ({
    type: UPDATE_ITEM_ID_TO_MOVE,
    payload: item_id,
});
