import React, {useEffect, useState} from 'react';
import Cookies from "js-cookie";
import {useSelector} from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import {Box, Container, IconButton, Stack, Typography} from "@mui/material";
import MessageCard from "./MessageCard";
import Grid from "@mui/material/Grid";
import CloseIcon from '@mui/icons-material/Close';
import CreateMessage from "./addMessageToChannel";

const {REACT_APP_BACKEND_API_URL} = process.env;

const ThreadView = ({activeThread, prepareActiveThread, channelId, openFullscreenImage, parentMessageId}) => {
    const {user} = useSelector((state) => state.auth);
    const [threadMessages, setThreadMessages] = useState([]);

    useEffect(() => {
        // Fetch channels from the API
        const fetchThreadMessages = async () => {
            if (activeThread === null || activeThread === -1) {
                setThreadMessages([]);
                return;
            }
            try {
                const accessToken = Cookies.get('access_token');
                const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/threads/${activeThread}/messages/`, {
                    headers: {
                        'Authorization': `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                setThreadMessages(response.data);
            } catch (error) {
                if (error?.response?.data?.message) {
                    toast.error("Error - ", error?.response?.data);
                } else {
                    toast.error('Error fetching thread messages:', error);
                }
            }
        };
        fetchThreadMessages();
    }, [activeThread]);

    const updateMessageStatus = (messageId) => {
        const messageIndex = threadMessages.findIndex(message => message.id === messageId);
        if (messageIndex !== -1) {
            const updatedMessages = [...threadMessages];
            updatedMessages[messageIndex] = {
                ...updatedMessages[messageIndex], action_taken: true,
            };
            setThreadMessages(updatedMessages);
        }
    }

    if (activeThread === null) {
        return null;
    }

    return (<Stack className={"full-parent"}>
            <Container className={"full-parent"} sx={{paddingTop: 2, overflowY: 'scroll', height: 'calc(100% - 50px)'}}>
                <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <Typography variant="h6" gutterBottom align="center">
                            Thread Replies
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={() => prepareActiveThread(null, null)}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        minWidth: '100%',
                        width: '100%',
                        overflow: 'auto'
                    }}
                >
                    {threadMessages.map((message) => (
                        <MessageCard message={message}
                                     isThread={true}
                                     prepareActiveThread={null}
                                     updateMessageStatus={updateMessageStatus}
                                     openFullscreenImage={openFullscreenImage}
                                     user={user}
                                     selectedMessageId={null}
                        />
                    ))}
                </Box>
            </Container>
            <CreateMessage messages={threadMessages} setMessages={setThreadMessages} channelId={channelId}
                           threadId={activeThread}
                           parentMessageId={parentMessageId}/>
        </Stack>
    );
};

export default ThreadView;


