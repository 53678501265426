import './App.css';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home"
import SocialAuth from "./pages/social-auth"
import {useDispatch} from 'react-redux'
import React, {useEffect} from "react";
import {loadUser} from "./redux/actions/login.action";
import SearchComponent from "./pages/Home/search";
import ChannelComponent from "./pages/Home/channel";
import HomeRoute from "./pages/Routes/homeRoute";
import Login from "./pages/Home/login";
import ProtectedRoute from "./pages/Routes/protectedRoute";


const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadUser());
    }, [dispatch]);

    return (
        <Routes>
            <Route element={<ProtectedRoute/>}>
                <Route exact path="/" element={<HomeRoute/>}/>
                <Route path="/dashboard" element={<Home/>}/>
                <Route path="/folder/:folderId" element={<Home/>}/>
                <Route path="/search" element={<SearchComponent/>}/>
                <Route path="/channels" element={<ChannelComponent/>}/>
            </Route>
            <Route exact path="/login" element={<Login/>}/>
            <Route exact path="/google" element={<SocialAuth/>}/>
        </Routes>
    )
        ;
};
export default App;
