import {
    Backdrop,
    Box,
    Chip, CircularProgress,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Divider,
    Fade,
    IconButton,
    Modal,
    TextField, Tooltip
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import MoveFolderModal from "./moveFolderDialog";
import SellIcon from "@mui/icons-material/Sell";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ArchiveIcon from "@mui/icons-material/Archive";
import Typography from "@mui/material/Typography";
import TagInputDialog from "./tagInput";
import {
    deleteFolderSuccess, deleteLinkSuccess, fetchFolders,
    folderAssigneeSuccess, linkAssigneeSuccess,
    renameFolderFailure,
    renameFolderSuccess, updateArchiveStatus, updateLinkSuccess
} from "../redux/actions/folders.action";
import AssigneeList from "./AsigneeList";
import Grid from "@mui/material/Grid";
import FolderAssigneeDropdown from "./folderAssigneeDropdown";
import AggregatorView from "./aggregatorView";
import MarkdownEditor from "@uiw/react-markdown-editor";
import Button from "@mui/material/Button";
import Comments from "./comments";
import UploadAttachment from "./uploadAttachment";
import React, {useEffect, useState} from "react";
import Cookies from "js-cookie";
import axios from "axios";
import toast from "react-hot-toast";
import {connect, useDispatch} from "react-redux";
import DescriptionIcon from "@mui/icons-material/Description";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ShortcutDialog from "./shortcutDialog";
import ShareIcon from '@mui/icons-material/Share';

const {REACT_APP_BACKEND_API_URL} = process.env;

const ItemDetailModal = ({
                             isOpen, handleClose, depth, itemData, updateNameHandler, setItemData, on_delete, classes,
                             href = null, itemInfo, fetchItemDetails, itemType
                         }) => {
    const [folder_name, setName] = useState(itemData.name);
    const [linkVal, setLinkVal] = useState(href);
    const [filterComment, setFilterComment] = useState(false);
    const [isArchive, setIsArchive] = useState(itemData.is_archived);
    const [openTag, setOpenTag] = useState(false);
    const [tags, setTags] = useState([]);
    const [recentTags, setRecentTags] = useState([]);
    const [comment, setComment] = useState('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isStarred, setIsStarred] = useState(itemData.is_starred);
    const [savingComment, setSavingComment] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isLinkEditing, setIsLinkEditing] = useState(false)
    const [mediaFile, setMediaFile] = useState(null);
    const [mediaType, setMediaType] = useState('');
    const [isCreatingDescriptionGoogleDoc, setIsCreatingDescriptionGoogleDoc] = useState(false);
    const [isDocLoading, setIsDocLoading] = useState(true);

    const dispatch = useDispatch();

    const itemDetails = itemInfo?.folder || itemInfo?.link;
    const itemComments = itemInfo?.folder_comments || itemInfo?.link_comments;

    const handleLinkChange = (event) => {
        setLinkVal(event.target.value);
    };

    const handleLinkClick = () => {
        setIsLinkEditing(true);
    };

    const handleTagDialogOpen = () => {
        setOpenTag(true);
    };

    const handleTagDialogClose = () => {
        setOpenTag(false);
    };

    const handleDeleteFolder = () => {
        setShowDeleteConfirmation(true);
    };

    const handleFilterCommentChange = () => {
        setFilterComment(!filterComment);
    }

    const handleNameClick = () => {
        setIsEditing(true);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleNameBlur = () => {
        setIsEditing(false);
    };

    const onFileUpload = (file_, type_) => {
        setMediaFile(file_);
        setMediaType(type_);
    }

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleCommentModify = (commentId, isDeleted, newComment) => {
        if (itemType === 'folder') {
            if (isDeleted) {
                handleDetailsChange({
                        ...itemInfo,
                        folder_comments: itemComments.filter(comment => comment.id !== commentId)
                    }
                )
            } else {
                const existingIndex = itemComments.findIndex(comment => comment.id === commentId);
                const newFolderData = {...itemInfo};
                newFolderData.folder_comments[existingIndex].comment = newComment;
                handleDetailsChange(newFolderData);
            }
        } else if (itemType === 'link') {
            if (isDeleted) {
                handleDetailsChange({
                        ...itemInfo,
                        link_comments: itemComments.filter(comment => comment.id !== commentId)
                    }
                )
            } else {
                const existingIndex = itemComments.findIndex(comment => comment.id === commentId);
                const newLinkData = {...itemInfo};
                newLinkData.link_comments[existingIndex].comment = newComment;
                handleDetailsChange(newLinkData);
            }
        }
    }

    const renameFolder = async (id, folder_name, depth) => {
        try {
            const accessToken = Cookies.get('access_token');
            const response = await axios.post(`${REACT_APP_BACKEND_API_URL}/api/folder/rename/${id}`, {
                name: folder_name,
            }, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const updatedItem = itemType === 'folder' ? response.data.folder : response.data.link;
            if (itemType === 'folder') {
                handleDetailsChange({
                    ...itemInfo,
                    folder: {
                        ...itemInfo.folder,
                        name: folder_name,
                    },
                    folder_comments: [
                        ...itemInfo.folder_comments,
                        response.data.comment
                    ]
                })
            } else if (itemType === 'link') {
                handleDetailsChange({
                    ...itemInfo,
                    link: {
                        ...itemInfo.link,
                        name: folder_name,
                    },
                    link_comments: [
                        ...itemInfo.link_comments,
                        response.data.comment
                    ]
                })
            }
            toast.success("Item Renamed Successfully.")
            if (depth !== -1 && itemType === 'folder') {
                dispatch(renameFolderSuccess(updatedItem, depth));
            } else if (depth !== -1 && itemType === 'link') {
                dispatch(updateLinkSuccess(updatedItem, depth));
            } else {
                updateNameHandler(id, folder_name)
            }
        } catch (error) {
            toast.error("Error Renaming Folder")
            if (depth !== -1) {
                dispatch(renameFolderFailure(error.message));
            }
        }
        setIsEditing(false);
    };

    const updateFolderName = (event) => {
        if (event.key === 'Enter' && folder_name.trim() !== '') {
            if (folder_name.trim() === itemData.name) {
                setIsEditing(false);
                return;
            }
            renameFolder(itemData.id, folder_name, depth).then()
        }
    }

    const updateLinkValue = (event) => {
        if (event.key === 'Enter' && linkVal.trim() !== '') {
            if (linkVal.trim() === itemDetails.link) {
                setIsLinkEditing(false);
                return;
            }
            try {
                new URL(linkVal);
            } catch (error) {
                toast.error("Please enter a valid Link.")
                return;
            }
            updateLink(itemData.id, linkVal, depth).then()
        }
    }

    const updateLink = async (id, link, depth) => {
        try {
            const accessToken = Cookies.get('access_token');
            const response = await axios.post(`${REACT_APP_BACKEND_API_URL}/api/link/update/${id}`, {
                link: link,
            }, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const link_data = response.data.link
            setItemData({
                ...itemInfo,
                link: {
                    ...itemInfo.link,
                    link: link,
                },
                link_comments: [
                    ...itemInfo.link_comments,
                    response.data.comment
                ]
            })
            toast.success("Link Updated Successfully.")
            if (depth !== -1) {
                dispatch(updateLinkSuccess(link_data, depth));
            } else {
                updateNameHandler(id, itemData.name, linkVal)
            }
        } catch (error) {
            toast.error("Error Updating Link")
        }
        setIsLinkEditing(false);
    };

    const handleShareLink = () => {
        const url = `${window.location.origin}/folder/${itemData.id}?archiveStatus=${Cookies.get('archiveStatus')}`;
        navigator.clipboard.writeText(url).then(() => {
            toast.success("Link Copied to Clipboard.");
        });
    }

    const handleArchiveClick = async () => {
        const newIsArchive = !isArchive;
        setIsArchive(newIsArchive);
        try {
            const accessToken = Cookies.get('access_token');
            const url = 'item_archive_status_change'
            const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/${url}/${itemData.id}?status=${newIsArchive}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            toast.success(`Archive Status set ${newIsArchive} successfully`);
            if (depth !== -1 && itemType === 'folder') {
                handleDetailsChange({
                    ...itemInfo,
                    folder: {
                        ...itemInfo.folder,
                        is_archived: newIsArchive
                    },
                });
                dispatch(renameFolderSuccess({
                    ...itemInfo.folder,
                    is_archived: newIsArchive
                }, depth));
            } else if (depth !== -1 && itemType === 'link') {
                handleDetailsChange({
                    ...itemInfo,
                    link: {
                        ...itemInfo.link,
                        is_archived: newIsArchive
                    },
                });
                dispatch(updateLinkSuccess({
                    ...itemInfo.link,
                    is_archived: newIsArchive
                }, depth));
            } else {
                handleDetailsChange({
                    ...itemInfo,
                    folder: {
                        ...itemInfo.folder,
                        is_archived: newIsArchive
                    },
                });
                updateNameHandler(itemData.id, folder_name, null, null, newIsArchive)
            }
        } catch (error) {
            toast.error("Error Updating Status");
        }
    }

    const handleStarClick = async () => {
        const newIsStarred = !isStarred;
        setIsStarred(newIsStarred);
        try {
            const accessToken = Cookies.get('access_token');
            const url = 'item_starred_value_change'
            const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/${url}/${itemData.id}?status=${newIsStarred}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            toast.success("Starred Status Updated Successfully.");
            if (depth !== -1 && itemType === 'folder') {
                handleDetailsChange({
                    ...itemInfo,
                    folder: {
                        ...itemInfo.folder,
                        is_starred: newIsStarred
                    },
                });
                dispatch(renameFolderSuccess({
                    ...itemInfo.folder,
                    is_starred: newIsStarred
                }, depth));
            } else if (depth !== -1 && itemType === 'link') {
                handleDetailsChange({
                    ...itemInfo,
                    link: {
                        ...itemInfo.link,
                        is_starred: newIsStarred
                    },
                });
                dispatch(updateLinkSuccess({
                    ...itemInfo.link,
                    is_starred: newIsStarred
                }, depth));
            } else {
                handleDetailsChange({
                    ...itemInfo,
                    folder: {
                        ...itemInfo.folder,
                        is_starred: newIsStarred
                    },
                });
                updateNameHandler(itemData.id, folder_name, null, newIsStarred)
            }
        } catch (error) {
            console.log(error)
            toast.error("Error Updating Status");
        }
    };

    const handleStatusChange = async (value) => {
        try {
            const accessToken = Cookies.get('access_token');
            const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/folder_status_change/${itemData.id}?status=${value}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            toast.success("Status Updated Successfully.");
            if (depth !== -1) {
                handleDetailsChange({
                    ...itemInfo,
                    folder: {
                        ...itemInfo.folder,
                        status: value
                    },
                    state_machine: response.data.state_machine
                });
                dispatch(renameFolderSuccess({
                    ...itemInfo.folder,
                    status: value
                }, depth));
            } else {
                handleDetailsChange({
                    ...itemInfo,
                    folder: {
                        ...itemInfo.folder,
                        status: value
                    },
                    state_machine: response.data.state_machine
                });
                updateNameHandler(itemData.id, folder_name, value)
            }
        } catch (error) {
            console.log(error)
            toast.error("Error Updating Status");
        }
    }

    const handleAssigneeSelect = (employees, comment) => {
        const newData = itemType === 'folder' ? {
            ...itemInfo,
            folder: {
                ...itemInfo.folder,
                employees: employees
            },
            folder_comments: [
                ...itemInfo.folder_comments,
                comment
            ]
        } : {
            ...itemInfo,
            link: {
                ...itemInfo.link,
                employees: employees
            },
            link_comments: [
                ...itemInfo.link_comments,
                comment
            ]
        }
        handleDetailsChange(newData)

        if (depth !== -1) {
            if (itemType === 'folder') {
                dispatch(folderAssigneeSuccess({...itemInfo.folder, employees}, depth));
            } else {
                dispatch(linkAssigneeSuccess({...itemInfo.link, employees}, depth));
            }
        }
    };

    const handleConfirmDelete = async () => {
        try {
            const accessToken = Cookies.get('access_token');
            const response = await axios.post(`${REACT_APP_BACKEND_API_URL}/api/${itemType}/delete/${itemData.id}`, {}, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            toast.success("Item Deleted Successfully.");
            handleClose();
            if (depth !== -1 && itemType === 'folder') {
                dispatch(deleteFolderSuccess(itemData.id, depth));
            } else if (depth !== -1 && itemType === 'link') {
                dispatch(deleteLinkSuccess(itemData.id, depth))
            } else {
                on_delete(itemData.id)
            }
        } catch (error) {
            toast.error("Error Deleting Folder");
        } finally {
            setShowDeleteConfirmation(false);
        }
    };

    const renderDeleteConfirmationDialog = () => (
        <Dialog
            open={showDeleteConfirmation}
            onClose={() => setShowDeleteConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this folder?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowDeleteConfirmation(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );

    const onAggregatorDataChange = (data) => {
        const existingIndex = itemInfo.aggregator_data.findIndex(entry => entry.key === data.key);
        const newFolderData = {...itemInfo};
        if (existingIndex !== -1) {
            newFolderData.aggregator_data[existingIndex] = data;
        } else {
            newFolderData.aggregator_data = [...itemInfo.aggregator_data, data];
        }
        handleDetailsChange(newFolderData);
    }

    const comments = () => {
        if (!itemComments) {
            return [];
        }
        return itemComments.filter(comment => {
            if (!filterComment) {
                return true;
            } else {
                return comment.is_comment;
            }
        })
    }

    const saveComment = async (event) => {
        if (event.key === 'Enter' && comment.trim() !== '') {
            try {
                setSavingComment(true);
                const formData = new FormData();
                formData.append('comment', comment)
                formData.append('media_type', mediaType)
                formData.append('media_file', mediaFile)

                const accessToken = Cookies.get('access_token');
                const response = await axios.post(`${REACT_APP_BACKEND_API_URL}/api/item/comment/add/${itemData.id}`, formData, {
                    headers: {
                        'Authorization': `Token ${accessToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                toast.success("Comment Saved Successfully.")
                setSavingComment(false);
                setComment('');
                setMediaType('');
                setMediaFile(null);
                if (itemType === 'folder') {
                    handleDetailsChange({
                            ...itemInfo,
                            folder_comments: [
                                ...itemInfo.folder_comments,
                                response.data
                            ]
                        }
                    )
                } else if (itemType === 'link') {
                    handleDetailsChange({
                            ...itemInfo,
                            link_comments: [
                                ...itemInfo.link_comments,
                                response.data
                            ]
                        }
                    )
                }
            } catch (error) {
                toast.error("Error Saving Comment.")
                setSavingComment(false);
            }
        } else if (event.key === 'Enter' && comment.trim() === '') {
            toast.error("Comment field can not be blank")
        }
    }

    const handleDetailsChange = (newData) => {
        setItemData(newData);
    };

    const isItemType = itemType === 'folder' ? itemInfo?.folder : itemInfo?.link;
    const hasDescriptionGdoc = isItemType?.descriptionGdocId?.length > 0;
    const descriptionGdocUrl = `https://docs.google.com/document/d/${isItemType?.descriptionGdocId}`

    const handleOpenInNewTab = () => {
        window.open(descriptionGdocUrl, '_blank');
    }

    const createDescriptionGoogleDoc = async () => {
        try {
            setIsCreatingDescriptionGoogleDoc(true)
            const accessToken = Cookies.get('access_token');
            const response = await axios.post(`${REACT_APP_BACKEND_API_URL}/api/folder/create_description_gdoc/${itemData.id}`, {}, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            await fetchItemDetails();
            setIsCreatingDescriptionGoogleDoc(false);
        } catch (error) {
            setIsCreatingDescriptionGoogleDoc(false);
            toast.error("Error Creating Description Google Doc")
        }
    };

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpen}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '5%',
                        height: '90%',
                        left: {xs: '5%', md: '20%'},
                        transform: 'translate(0%, 0%)',
                        width: {xs: '90%', md: '60%'},
                        bgcolor: 'white',
                        borderRadius: '4px',
                        boxShadow: 24,
                        p: 2.5,
                        paddingTop: 0.5,
                        overflow: 'auto'
                    }}
                >
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <IconButton className={"close-button"} onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                        <Box>
                            <IconButton onClick={handleDeleteFolder} className={"close-button"}>
                                <DeleteIcon/>
                            </IconButton>
                            <MoveFolderModal className={"close-button"} folder_id={itemData.id} />
                            <Tooltip title={"Tag"}>
                                <IconButton onClick={handleTagDialogOpen} className={"close-button"}>
                                    <SellIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={isStarred ? "Unstar" : "Star"}>
                                <IconButton onClick={handleStarClick} className={"close-button"}
                                            style={{color: isStarred ? '#f5667b' : ''}}>
                                    {isStarred ? <StarIcon/> : <StarBorderIcon/>}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={isArchive ? "Unarchive" : "Archive"}>
                                <IconButton className={"close-button"} onClick={handleArchiveClick}>
                                    {isArchive ? <UnarchiveIcon/> : <ArchiveIcon/>}
                                </IconButton>
                            </Tooltip>
                            <ShortcutDialog className={"close-button"} folder_id={itemData.id}/>
                            <Tooltip title={"Share Link"}>
                                <IconButton onClick={handleShareLink} className={"close-button"}>
                                    <ShareIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    {renderDeleteConfirmationDialog()}

                    {itemType === 'link' &&
                        <a href={href} rel="noopener noreferrer" target="_blank"
                           style={{textDecoration: 'none', color: 'inherit', float: "right"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32"
                                 style={{height: 40, width: "40 !important", marginTop: "8px", marginRight: 10}}>
                                <g>
                                    <path
                                        d="M9.44,28a4.37,4.37,0,0,0,3.08-1.27l6.22-6.22a4.34,4.34,0,0,0,.3-5.76.65.65,0,0,0-.15-.18l-.18-.19h0a1,1,0,0,0-1.41,1.41h0a2.33,2.33,0,0,1,0,3.29L11.1,25.31a2.34,2.34,0,0,1-3.32,0L6.69,24.22a2.34,2.34,0,0,1,0-3.32l2.73-2.74A1,1,0,1,0,8,16.75L5.27,19.48a4.36,4.36,0,0,0,0,6.15l1.1,1.1A4.35,4.35,0,0,0,9.44,28Z"/>
                                    <path
                                        d="M25.63,5.27a4.36,4.36,0,0,0-6.15,0l-6.22,6.22a4.34,4.34,0,0,0-.3,5.76.65.65,0,0,0,.15.18l.18.19h0a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41h0a2.33,2.33,0,0,1,0-3.29L20.9,6.69a2.34,2.34,0,0,1,3.32,0l1.09,1.09a2.34,2.34,0,0,1,0,3.32l-2.73,2.74A1,1,0,1,0,24,15.25l2.74-2.73a4.36,4.36,0,0,0,0-6.15Z"/>
                                </g>
                            </svg>
                        </a>}

                    {itemInfo ? (
                        <>
                            <Box sx={{marginBottom: 2}}>
                                {isEditing ? (
                                    <TextField
                                        value={folder_name}
                                        onChange={handleNameChange}
                                        onBlur={handleNameBlur}
                                        onKeyPress={updateFolderName}
                                        autoFocus
                                        fullWidth
                                        variant="outlined"
                                        label="Folder Name"
                                        spellCheck={false}
                                        sx={{width: '90%'}}
                                    />
                                ) : (
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        gutterBottom
                                        onClick={handleNameClick}
                                        sx={{width: '90%', cursor: 'pointer'}}
                                    >
                                        {itemDetails.name}
                                    </Typography>
                                )}

                                {isLinkEditing && itemType === 'link' ? (
                                    <TextField
                                        value={linkVal}
                                        onChange={handleLinkChange}
                                        onKeyPress={updateLinkValue}
                                        autoFocus
                                        fullWidth
                                        variant="outlined"
                                        label="Link"
                                        style={{width: "90%"}}
                                    />
                                ) : (
                                    <Typography
                                        variant="h6"
                                        component="h2"
                                        gutterBottom
                                        onClick={handleLinkClick}
                                        style={{width: "90%", cursor: 'pointer'}}

                                    >
                                        {itemDetails.link}
                                    </Typography>
                                )}
                            </Box>

                            <Box sx={{textAlign: 'center', marginBottom: 2}}>
                                {tags?.map((tag, index) => (
                                    <Chip key={index} label={tag.name} sx={{marginRight: 1}}/>
                                ))}
                            </Box>

                            <TagInputDialog
                                open={openTag}
                                onClose={handleTagDialogClose}
                                onSave={(updatedTags) => {
                                    setTags(updatedTags);
                                    handleDetailsChange({
                                        ...itemInfo,
                                        folder: {
                                            ...itemInfo.folder,
                                            tags: updatedTags
                                        },
                                    });
                                    if (depth !== -1) {
                                        dispatch(renameFolderSuccess({
                                            ...itemInfo.folder,
                                            tags: updatedTags
                                        }, depth));
                                    } else {
                                        updateNameHandler(itemData.id, folder_name, null, null, updatedTags);
                                    }
                                }}
                                existingTags={tags}
                                recentTags={recentTags}
                                setRecentTags={setRecentTags}
                                id={itemData.id}
                                type={"add_tag_to_item"}
                            />
                            <AssigneeList folderData={itemInfo} users={itemInfo.users} itemType={itemType}
                                          onSelectAssignee={handleAssigneeSelect} itemDetails={itemDetails}/>
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                                <Grid item xs={12} sm={3}>
                                    <FolderAssigneeDropdown itemDetails={itemDetails} folderData={itemInfo}
                                                            onSelectAssignee={handleAssigneeSelect}
                                                            itemType={itemType}/>
                                </Grid>
                                {itemDetails.author && (
                                    <Grid item xs={12} sm={3}>
                                        <Box
                                            sx={{
                                                backgroundColor: '#f8f9fb',
                                                borderRadius: '10px',
                                                padding: 1,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Typography variant="body1" gutterBottom>
                                                Author
                                            </Typography>
                                            <Divider sx={{marginBottom: 1}}/>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                <img
                                                    src={itemDetails.author.picture}
                                                    alt="Author's Picture"
                                                    style={{
                                                        height: 30,
                                                        width: 30,
                                                        borderRadius: '50%',
                                                        marginRight: 10
                                                    }}
                                                />
                                                <Typography variant="body1">
                                                    {itemDetails.author.first_name} {itemDetails.author.last_name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )}
                                {itemDetails.created_at && (
                                    <Grid item xs={12} sm={3}>
                                        <Box
                                            sx={{
                                                backgroundColor: '#f8f9fb',
                                                borderRadius: '10px',
                                                padding: 1,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Typography variant="body1" gutterBottom>
                                                Created Date
                                            </Typography>
                                            <Divider sx={{marginBottom: 1}}/>
                                            <Typography>
                                                {new Date(itemDetails.created_at).toLocaleDateString()}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {itemDetails.id && (
                                    <Grid item xs={12} sm={3}>
                                        <Box
                                            sx={{
                                                backgroundColor: '#f8f9fb',
                                                borderRadius: '10px',
                                                padding: 1,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Typography variant="body1" gutterBottom>
                                                ID
                                            </Typography>
                                            <Divider sx={{marginBottom: 1}}/>
                                            <Typography>
                                                {itemDetails.id}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>

                            {itemType === 'folder' &&
                                <Box sx={{display: 'flex', alignItems: 'center', marginTop: 2, marginBottom: 2}}>
                                    <Typography variant="body1">
                                        Status:
                                    </Typography>
                                    <Box sx={{display: 'flex', marginLeft: 1}}>
                                        <Chip key={itemDetails.status} label={itemDetails.status}
                                              sx={{marginLeft: 1, backgroundColor: 'rgba(56,220,175,0.6)'}}/>
                                        {itemInfo.state_machine && itemInfo.state_machine.map(state => (
                                            <Chip key={state} label={state} sx={{marginLeft: 1}}
                                                  onClick={() => handleStatusChange(state)}/>
                                        ))}
                                    </Box>
                                </Box>}

                            <AggregatorView folderId={itemDetails.id} data={itemInfo.aggregator_data}
                                            onDataChange={onAggregatorDataChange}/>

                            <Box sx={{marginTop: 2, marginBottom: 2}}>
                                <Box sx={{display: 'flex', alignItems: 'center', height: 35, width: '100%'}}>
                                    <Typography variant="h6" gutterBottom sx={{marginBottom: 0}}>
                                        Description:
                                    </Typography>
                                    {isCreatingDescriptionGoogleDoc ? (
                                        <Box sx={{display: 'flex', alignItems: 'center', marginLeft: 2}}>
                                            <CircularProgress size="1rem" sx={{marginRight: 1}}/>
                                            <Typography variant="body2" sx={{fontSize: '0.875rem'}}>Creating Description
                                                Google Doc...</Typography>
                                        </Box>
                                    ) : !hasDescriptionGdoc ? (
                                        <Button variant="outlined" onClick={createDescriptionGoogleDoc}
                                                startIcon={<DescriptionIcon/>} sx={{
                                            marginLeft: 2,
                                            height: 'auto',
                                            padding: '4px 10px',
                                            fontSize: '0.875rem'
                                        }}>
                                            Create Description Google Doc
                                        </Button>
                                    ) : (
                                        <Button variant="outlined" onClick={handleOpenInNewTab}
                                                endIcon={<OpenInNewIcon/>} sx={{
                                            marginLeft: 'auto',
                                            height: 'auto',
                                            padding: '4px 10px',
                                            fontSize: '0.875rem'
                                        }}>
                                            Open in new tab
                                        </Button>
                                    )}
                                </Box>
                            </Box>

                            <Divider sx={{marginBottom: 2}}/>

                            {hasDescriptionGdoc && (
                                <Box sx={{position: 'relative'}}>
                                    {isDocLoading && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(211, 211, 211, 0.6)',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                zIndex: 1,
                                            }}
                                        >
                                            <CircularProgress/>
                                        </Box>
                                    )}
                                    <iframe
                                        src={descriptionGdocUrl}
                                        width="100%"
                                        height="600px"
                                        onLoad={() => setIsDocLoading(false)}
                                        style={{filter: isDocLoading ? 'blur(2px)' : 'none', zIndex: 0}}
                                    ></iframe>
                                </Box>
                            )}

                            {itemComments && itemComments.length > 0 && (
                                <Comments comments={comments()} filterComponent={handleFilterCommentChange}
                                          filterEnabled={filterComment} tableName={itemType}
                                          onModification={handleCommentModify}/>
                            )}

                            <Grid container spacing={2}>
                                {savingComment ? (
                                    <Typography>Saving Comment...</Typography>
                                ) : (
                                    <>
                                        <Grid item xs={10}>
                                            <TextField
                                                variant="outlined"
                                                label="Comment"
                                                value={comment}
                                                spellCheck={false}
                                                onKeyPress={saveComment}
                                                onChange={handleCommentChange}
                                                fullWidth
                                                sx={{marginTop: 2}}
                                            />
                                        </Grid>
                                        <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                                            <UploadAttachment onFileChange={onFileUpload}/>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <Typography variant="body1" gutterBottom>
                            Loading folder details...
                        </Typography>
                    )}
                </Box>
            </Fade>
        </Modal>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    renameFolderSuccess,
    deleteFolderSuccess,
    folderAssigneeSuccess,
    updateArchiveStatus,
    fetchFolders
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetailModal);