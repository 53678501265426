import React, {useEffect, useRef, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {
    deleteFolderSuccess,
    fetchChildFolders,
    folderAssigneeSuccess,
    renameFolderSuccess,
    updateArchiveStatus
} from "../redux/actions/folders.action";
import {Chip, Stack, Typography} from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import 'react-quill/dist/quill.snow.css';
import Cookies from "js-cookie";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import Avatar from "@mui/material/Avatar";
import {stringToColor} from "../utils/utils";
import ItemDetailsModal from "./ItemDetailsModal";
import {configureKeyboardNavigation} from "../redux/actions/global.action";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

const Folder = ({
                    name,
                    data,
                    id,
                    depth,
                    selected_folders,
                    is_archived,
                    updateNameHandler,
                    on_delete,
                    children,
                    agg_data,
                    parents,
                    f,
                    focusedIndex,
                    open_folder
                }) => {
    const user_settings = useSelector((state) => state.auth.user_settings);
    const isMinimalView = user_settings?.minimal_mode;

    const [isFocused, setIsFocused] = useState(false);
    const [folderData, setFolderData] = useState(null); // State to hold folder data
    const [isOpen, setIsOpen] = useState(false);

    const history = useNavigate();
    const folderRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (open_folder === id) {
            handleOpen();
        }
    }, [open_folder, id]);

    // BEing used for both cliack and arrow key navigation
    const onFolderClick = () => {
        if (depth !== -1) {
            dispatch(fetchChildFolders(id, depth, Cookies.get('archiveStatus')));
            history(`/folder/${id}`);
        }
    }

    useEffect(() => {
        if (depth !== -1 && focusedIndex === f) {
            onFolderClick();
            if (folderRef.current) {
                folderRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest'});
            }
        }
        setIsFocused(f === focusedIndex)
    }, [focusedIndex, f]);

    const handleFolderClick = (e) => {
        e.preventDefault();
        if (e.type === 'click' && e.detail === 2) { // Double-click
            handleOpen();
        } else if (e.type === 'contextmenu') { // Right-click
            e.preventDefault();
            handleOpen();
        } else {
            if (depth !== -1) {
                dispatch(fetchChildFolders(id, depth, Cookies.get('archiveStatus')));
                history(`/folder/${id}`);
            } else {
                const folderUrl = `/folder/${id}`;
                window.open(folderUrl, '_blank');
            }
        }
    };

    let selected = false;
    if (selected_folders.includes(id)) {
        selected = true
    }

    const fetchFolderDetails = async () => {
        try {
            const {REACT_APP_BACKEND_API_URL} = process.env;
            const access_token = Cookies.get("access_token")
            const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/folder/${id}`, {
                headers: {
                    'Authorization': `Token ${access_token}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.data;
            setFolderData(data);
        } catch (error) {
            console.error('Error fetching folder details:', error);
        }
    };
    useEffect(() => {
        if (isOpen) {
            fetchFolderDetails();
        }
    }, [isOpen]);


    const handleOpen = () => {
        setIsOpen(true);
        dispatch(configureKeyboardNavigation(false))
    };

    const handleClose = () => {
        setIsOpen(false);
        dispatch(configureKeyboardNavigation(true))
    };

    const get_status = () => {
        let status = data.status;
        const custom_status_order = ["Taking Longer", "Doing", "Doing-in-bg", "Paused", "TODO", "Block-on-asm",
            "Blocked-on-purchase", "Blocked-on-discussion", "Blocked", "Done"]
        if (status.length <= 2) {
            status = custom_status_order[parseInt(status, 10) - 1];
        }
        return status
    }

    const handleUpdateFolderData = (updatedData) => {
        setFolderData(updatedData);
    };

    const renderAggregatorChips = () => {
        if (!agg_data) {
            return null;
        }

        return Object.keys(agg_data).map((key, index) => (
            <Chip
                key={index}
                variant="outlined"
                label={`${key}: ${agg_data[key]}`}
                style={{marginRight: '5px', fontSize: '10px', height: '20px'}}
            />
        ));
    }

    return (
        <>
            <Stack direction="row" width={"100%"}>
                <Stack direction="row" justifyContent={"space-between"}
                       className={`folder-container text-container-par`} onClick={handleFolderClick} ref={folderRef}
                       sx={{
                           width: parents ? '40%' : '100%',
                           backgroundColor: isFocused && depth !== -1 ? 'rgba(71,182,175,0.25)' : selected ? '#c4c3c3' : '#f5f5f5'
                       }}
                       onContextMenu={handleFolderClick}>
                    <Stack width={'calc( 100% - 60px )'} alignItems='flex-start' justifyContent='flex-start'>
                        <Stack direction="row" width={'100%'} spacing={0.5} sx={{
                            flex: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}>
                            {data.is_starred ? <StarIcon style={{color: '#d5406c'}}/> : <StarBorderIcon/>}
                            <FolderOutlinedIcon/>
                            <Typography className={"text-container"}
                                        sx={{color: is_archived ? 'gray' : 'black'}}>
                                {name}
                            </Typography>
                        </Stack>
                        {!isMinimalView && <Stack direction="row" width={'100%'}>
                            {/*{depth !== -1 && <Chip*/}
                            {/*    key={-1}*/}
                            {/*    label={Object.keys(children).length > 0 ? `${children.unassigned_children_count}/${children.children_count}` : `0/0`}*/}
                            {/*    style={{marginRight: '5px', fontSize: '10px', height: '20px'}}*/}
                            {/*/>}*/}
                            {renderAggregatorChips()}
                            {data.tags.slice(0, 2).map((tag, index) => (
                                <Chip
                                    key={index}
                                    label={tag.name}
                                    style={{marginRight: '5px', fontSize: '10px', height: '20px'}}
                                />
                            ))}
                        </Stack>}
                    </Stack>

                    {!isMinimalView && <Stack width={'40px'} alignItems={"center"} justifyContent={"center"}>
                        {data.employees.map((employee, index) => (index === 0 &&
                            <Avatar
                                key={index}
                                alt="No Image"
                                style={{
                                    height: 30,
                                    width: 30,
                                    borderRadius: '25%',
                                    fontSize: "11px",
                                    backgroundColor: stringToColor(employee.first_name + employee.last_name),
                                    color: 'white',
                                }}
                            >
                                {employee.first_name.charAt(0) + (employee.first_name.length > 1 ? employee.first_name.charAt(1) : '') + employee.last_name.charAt(0) + (employee.last_name.length > 1 ? employee.last_name.charAt(1) : '')}{data.employees.length > 1 ? '..' : ''}
                            </Avatar>
                        ))}
                        <span style={{fontSize: "9px", textAlign: 'center'}}>{get_status()}</span>
                    </Stack>}
                </Stack>
                {depth === -1 && parents && parents.length > 0 && <div style={{marginLeft: 50}}>
                    <Chip
                        label={
                            <span style={{
                                display: 'inline-block',
                                width: '100%',
                                overflowX: 'auto',
                                whiteSpace: 'nowrap',
                                padding: 2
                            }}>
                        {parents.map((parent, index) => (
                            <React.Fragment key={parent.id}>
                                {index > 0 && ' ----> '}
                                {parent.name}
                            </React.Fragment>
                        ))}
                    </span>
                        }
                    />
                </div>}
            </Stack>

            {folderData &&
                <ItemDetailsModal isOpen={isOpen} handleClose={handleClose} depth={depth} itemData={data}
                                  itemInfo={folderData} updateNameHandler={updateNameHandler}
                                  setItemData={handleUpdateFolderData} on_delete={on_delete}
                                  itemType={'folder'} fetchItemDetails={fetchFolderDetails}/>}
        </>
    );
};

const mapStateToProps = (state) => ({
    open_folder: state.folders.open_folder
});

const mapDispatchToProps = {
    renameFolderSuccess,
    deleteFolderSuccess,
    folderAssigneeSuccess,
    updateArchiveStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Folder);
