export const hashCode = (str) => {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }
    return hash >>> 0;
};

export const stringToColor = (str) => {
    let hash = hashCode(str);
    hash = hash>>6;
    return "#" + ((hash & 0xFF0FFF) << 0).toString(16).padStart(6, '0');
};

export const downloadFile = (downloadUrl) => {
    const fileName = downloadUrl.split('/media/').slice(-1)[0];
    const anchor = document.createElement('a');
    anchor.href = downloadUrl;
    anchor.download = fileName;
    anchor.target = "_blank";
    anchor.click();
};

export const truncateStringWithShortcut = (str, maxLength) => {
    const suffix = ".....(Shortcut)";
    if (str.length <= maxLength) {
        return str;
    }
    const truncationLength = maxLength - suffix.length;
    if (truncationLength <= 0) {
        return suffix;
    }
    return str.slice(0, truncationLength) + suffix;
}