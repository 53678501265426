import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Chip, Divider,
} from '@mui/material';
import Cookies from "js-cookie";
import axios from "axios";
import toast from "react-hot-toast";

const {REACT_APP_BACKEND_API_URL} = process.env;
const TagInputDialog = ({open, onClose, onSave, existingTags, id, type, recentTags, setRecentTags}) => {
    const [newTag, setNewTag] = useState('');

    const handleAddTag = async (tag = null) => {
        if (newTag.trim() !== '') {

            try {
                const accessToken = Cookies.get('access_token');
                const response = await axios.post(`${REACT_APP_BACKEND_API_URL}/api/${type}/${id}`, {
                    tag: newTag,
                }, {
                    headers: {
                        'Authorization': `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                const updatedTags = response.data.data.tags;
                const recent_tags = response.data.recent_tags;
                setRecentTags(recent_tags);

                onSave(updatedTags);

                toast.success("Tag Added Successfully.")
            } catch (error) {

                toast.error("Error Adding Tag.")

            }
            setNewTag('');
        } else if (tag !== null) {
            try {
                const accessToken = Cookies.get('access_token');
                const response = await axios.post(`${REACT_APP_BACKEND_API_URL}/api/${type}/${id}`, {
                    tag: tag,
                }, {
                    headers: {
                        'Authorization': `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                const updatedTags = response.data.data.tags;
                const recent_tags = response.data.recent_tags;
                setRecentTags(recent_tags);

                onSave(updatedTags);

                toast.success("Tag Added Successfully.")
            } catch (error) {
                toast.error("Error Adding Tag.")

            }
            setNewTag('');
        }
    };

    const handleDeleteTag = async (tagToDelete) => {
        let deleteType = "remove_tag_from_item";

        try {
            const accessToken = Cookies.get('access_token');
            const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/${deleteType}/${id}/tag/${tagToDelete}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const updatedTags = response.data.tags;

            onSave(updatedTags);

            toast.success("Tag Removed Successfully.")
        } catch (error) {
            toast.error("Error Removing Tag.")

        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle> Tags</DialogTitle>
            <DialogContent>
                <div style={{textAlign: "center"}}>
                    {existingTags?.map((tag, index) => (
                        <Chip
                            key={index}
                            label={tag.name}
                            onDelete={() => handleDeleteTag(tag.id)}
                            style={{marginRight: '5px', marginBottom: '5px'}}
                        />
                    ))}
                </div>

                <Divider/>
                <h4>Recent Tags</h4>
                <div style={{textAlign: "center"}}>
                    {recentTags?.map((tag, index) => (
                        <Chip
                            key={index}
                            label={tag}
                            onClick={() => {
                                handleAddTag(tag);
                            }}
                            style={{marginRight: '5px', marginBottom: '5px'}}
                        />
                    ))}
                </div>
                <div style={{textAlign: "center"}}>
                    <TextField
                        label="New Tag"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleAddTag();
                            }
                        }}
                        style={{marginTop: '10px'}}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleAddTag} disabled={!newTag.trim()} color="primary">
                    Add Tag
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TagInputDialog;
