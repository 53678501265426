import React, {useState} from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton} from '@mui/material';
import FolderDropdown from "./FolderDropdown";
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import Cookies from "js-cookie";
import axios from "axios";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {fetchAllFolderdetails, fetchFolders} from "../redux/actions/folders.action";
import {connect} from "react-redux";

const {REACT_APP_BACKEND_API_URL} = process.env;
const ChannelToComment = ({ channel_message, msgModifyCb}) => {
    const [open, setOpen] = useState(false);
    const [selectedFolderId, setSelectedFolderId] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedFolderId(''); // Reset selected folder when closing modal
    };

    const handleFolderSelect = (folderId) => {
        setSelectedFolderId(folderId);
    };

    const handleAddComment = async () => {
        if (selectedFolderId) {
            try {

                const formData = new FormData();
                formData.append('comment', channel_message.message)
                formData.append('media_type', channel_message.media_type)
                formData.append('channel_message_id', channel_message.id)
                if (channel_message.media_url !== null) {
                    formData.append('media_url', channel_message.media_url)
                }

                const accessToken = Cookies.get('access_token');
                const response = await axios.post(`${REACT_APP_BACKEND_API_URL}/api/item/comment/add/${selectedFolderId}`, formData, {
                    headers: {
                        Authorization: `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                msgModifyCb(channel_message.id)
                toast.success("Comment added Successfully."
                )
            } catch (error) {
                toast.error("Error Moving Folder");
            }

            setOpen(false);
            setSelectedFolderId('');
        }
    };

    return (
        <>
            <IconButton onClick={handleOpen}>
                <DriveFileMoveIcon/>
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth sx={{ '& .MuiDialog-paper': { width: '80%' } }}>
                <DialogTitle>Add as Comment</DialogTitle>
                <DialogContent>
                    <FolderDropdown onFolderSelect={handleFolderSelect} folder_id={null}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddComment} disabled={!selectedFolderId} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ChannelToComment;
