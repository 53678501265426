import React, {useState} from 'react';
import {
    Card,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import FilterListIcon from "@mui/icons-material/FilterList";
import ReactAudioPlayer from "react-audio-player";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Grid from "@mui/material/Grid";
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CancelIcon from '@mui/icons-material/Cancel';
import {useSelector} from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import LinkifyTypography from "./LinkifyTypography";
import {downloadFile} from "../utils/utils";

const {REACT_APP_BACKEND_API_URL} = process.env;

const Comments = ({comments, filterComponent, filterEnabled, tableName, onModification}) => {

    const {user} = useSelector((state) => state.auth);
    const username = user.username;

    const [showCommentDeleteConfirmation, setShowCommentDeleteConfirmation] = useState(false);
    const [deleteCommentId, setDeleteCommentId] = useState(null);
    const [editCommentId, setEditCommentId] = useState(null);
    const [newComment, setNewComment] = useState(null);

    const mediaPreview = (media_file, media_type) => {
        let preview = null;
        if (media_file === null || media_type === null) {
            return preview;
        }

        if (media_type === 'image') {
            preview = <Card
                sx={{
                    maxHeight: 200,
                    maxWidth: 450,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}>
                <CardMedia
                    component="img"
                    src={media_file}
                    alt="Uploaded Image"
                    sx={{
                        height: 'auto', width: 'auto', objectFit: 'contain', maxHeight: 200, maxWidth: 450
                    }}
                />
            </Card>
        } else if (media_type === 'video') {
            preview = <CardMedia
                component="video"
                controls
                src={media_file}
                title="Uploaded Video"
                sx={{height: 200}}
            />
        } else if (media_type === 'audio') {
            preview = <ReactAudioPlayer
                src={media_file}
                controls
            />
        } else {
            preview = <IconButton onClick={() => downloadFile(media_file)}><CloudDownloadIcon/> <Typography
                sx={{marginLeft: 1}}> Attachment </Typography></IconButton>
        }
        return <div style={{marginLeft: 40}}>{preview}</div>;
    }

    const renderDeleteCommentConfirmationDialog = () => (<Dialog
        open={showCommentDeleteConfirmation}
        onClose={() => setShowCommentDeleteConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this comment?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setShowCommentDeleteConfirmation(false)} color="primary">
                Cancel
            </Button>
            <Button onClick={handleConfirmCommentDelete} color="primary" autoFocus>
                Delete
            </Button>
        </DialogActions>
    </Dialog>);

    const handleConfirmCommentDelete = async () => {
        try {
            const accessToken = Cookies.get('access_token');
            const response = await axios.post(`${REACT_APP_BACKEND_API_URL}/api/item/comment/delete/${deleteCommentId}`, {}, {
                headers: {
                    'Authorization': `Token ${accessToken}`, 'Content-Type': 'application/json',
                },
            });
            onModification(deleteCommentId, true, null);
            toast.success("Comment Deleted Successfully.");
        } catch (error) {
            toast.error("Error Deleting Comment.");
        } finally {
            setShowCommentDeleteConfirmation(false);
            setDeleteCommentId(null)
        }
    };

    const saveNewComment = async (event) => {
        if (event.key === 'Enter' && newComment.trim() !== '') {
            try {
                const accessToken = Cookies.get('access_token');
                const response = await axios.post(`${REACT_APP_BACKEND_API_URL}/api/${tableName}/comment/edit/${editCommentId}`, {"comment": newComment}, {
                    headers: {
                        'Authorization': `Token ${accessToken}`, 'Content-Type': 'application/json',
                    },
                });
                onModification(editCommentId, false, newComment);
                toast.success("Comment Edited Successfully.");
            } catch (error) {
                toast.error("Error Editing Comment.");
            } finally {
                setShowCommentDeleteConfirmation(false);
                setEditCommentId(null);
                setNewComment(null);
            }
        }
    }

    const handleDeleteComment = (commentId) => {
        setDeleteCommentId(commentId)
        setShowCommentDeleteConfirmation(true);
    };

    const handleEditComment = (commentId, message) => {
        if (editCommentId === commentId) {
            setNewComment(null);
            setEditCommentId(null);
        } else {
            setNewComment(message);
            setEditCommentId(commentId);
        }
    }

    return (<div>
        <IconButton style={{
            float: "right", color: filterEnabled ? "black" : "grey"
        }} onClick={filterComponent}>
            <FilterListIcon/>
        </IconButton>
        <Typography variant="h6" gutterBottom>
            Comments:
        </Typography>
        <Divider style={{marginBottom: 10}}/>
        <div style={{maxHeight: 400, overflowY: "auto"}}
             className={"scrollbar-width"}>
            {comments.map((comment) => (<Grid container key={comment.id}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent={"space-between"}>
                            <Stack direction="row">
                                <img
                                    src={comment.user.picture}
                                    alt="Author's Picture"
                                    style={{
                                        height: 30, width: 30, borderRadius: '50%', marginRight: 10,
                                    }}
                                />
                                <Typography variant="body1" style={{fontWeight: "bold"}}>
                                    {comment.user.first_name} {comment.user.last_name} {moment(comment.created_at).fromNow()}
                                </Typography>
                            </Stack>
                            <Stack direction="row">
                                {comment.is_comment && comment.user.username === username &&
                                    <IconButton onClick={() => handleEditComment(comment.id, comment.comment)}>
                                        {editCommentId !== comment.id ? <EditNoteIcon/> : <CancelIcon/>}
                                    </IconButton>}
                                {comment.is_comment && comment.user.username === username &&
                                    <IconButton onClick={() => handleDeleteComment(comment.id)}>
                                        <DeleteIcon/>
                                    </IconButton>}
                            </Stack>
                        </Stack>

                    </Grid>
                    <Grid item xs={12}>
                        {editCommentId === comment.id ? <TextField
                            variant="outlined"
                            autoFocus={true}
                            size="small"
                            label="New Comment"
                            value={newComment}
                            onKeyPress={saveNewComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            style={{width: '100%', marginTop: '10px'}}
                        /> : <LinkifyTypography style={{marginLeft: 40}}>
                            {comment.comment}
                        </LinkifyTypography>}

                        {mediaPreview(comment.media_url, comment.media_type)}
                    </Grid>
                </Grid>

            ))}
            {comments.length === 0 && <div>
                <Typography>
                    No Comments
                </Typography>
            </div>}
        </div>
        {renderDeleteCommentConfirmationDialog()}
    </div>);
};

export default Comments;