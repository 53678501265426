import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import FoldersComponent from "../../components/folders";
import Cookies from "js-cookie";
import {fetchAllFolderdetails, fetchFolders, openDetailPane} from "../../redux/actions/folders.action";
import {useLocation, useParams} from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import {setItemIdToMove} from "../../redux/actions/global.action";

const {REACT_APP_BACKEND_API_URL} = process.env;

const Home = ({
                  accessToken,
                  folders,
                  fetchFolders,
                  selected_folders,
                  fetchAllFolderdetails,
                  keyboardNavigationActive,
                  itemIdToMove
              }) => {

    const dispatch = useDispatch();

    const location = useLocation();
    const {folderId} = useParams();
    const [focusedIndex, setFocusedIndex] = useState(null);
    const [currentDepth, setCurrentDepth] = useState(1);
    const [selectedFolderIdCopy, setSelectedFolderIdCopy] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const archiveStatus = params.get('archiveStatus');
        if (archiveStatus) {
            Cookies.set('archiveStatus', archiveStatus);
        }
    }, [location]);

    const handleKeyDown = (event) => {
        if (!keyboardNavigationActive) return;
        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();
                moveFocus(-1);
                break;
            case 'ArrowDown':
                event.preventDefault();
                moveFocus(1);
                break;
            case 'ArrowLeft':
                moveFocusToChild(-1);
                break;
            case 'ArrowRight':
                moveFocusToChild(1);
                break;
            case 'Enter':
                dispatch(openDetailPane(folderId));
                break;
            case 'c':
            case 'C':
                if (event.ctrlKey || event.metaKey) {
                    setSelectedFolderIdCopy(folderId);
                    toast.success("Item selected for creating shortcut.")
                }
                break;
            case 'x':
            case 'X':
                if (event.ctrlKey || event.metaKey) {
                    dispatch(setItemIdToMove(folderId))
                    toast.success("Item selected for moving.")
                }
                break;
            case 'v':
            case 'V':
                if (event.ctrlKey || event.metaKey) {
                    moveFolderV2(folderId)
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentDepth, focusedIndex, folders?.folders, keyboardNavigationActive]);

    const moveFocus = (step) => {
        const currentFocusedIndex = focusedIndex !== null ? focusedIndex : `${currentDepth}_-1`;
        const actualDepth = parseInt(currentFocusedIndex.split("_")[0])
        const nextIndex = parseInt(currentFocusedIndex.split("_")[1]) + step;
        if (nextIndex >= 0 && nextIndex < folders.folders[actualDepth].length) {
            setFocusedIndex(`${actualDepth}_${nextIndex}`);
        }
    };

    const moveFolderV2 = async (targetFolderId) => {
        if ((itemIdToMove === null || itemIdToMove === -1) && (selectedFolderIdCopy === null || selectedFolderIdCopy === -1) ) {
            toast.error("Initial item missing, Copy/Cut initial target item.");
            return;
        }
        try {
            const accessToken = Cookies.get('access_token');
            let url;
            if (itemIdToMove !== null && itemIdToMove !== -1) {
                const params = `folder_id=${targetFolderId}`
                url = `${REACT_APP_BACKEND_API_URL}/api/move_item/${itemIdToMove}?${params}`;
            } else if (selectedFolderIdCopy !== null && selectedFolderIdCopy !== -1) {
                url = `${REACT_APP_BACKEND_API_URL}/api/create_folder_shortcut/?original_folder_id=${selectedFolderIdCopy}&destination_folder_id=${targetFolderId}`;
            }
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            toast.success("Item Moved Successfully.")
            fetchAllFolderdetails(targetFolderId)
        } catch (error) {
            toast.error("Error Moving Item");
        }
        dispatch(setItemIdToMove(null))
        setSelectedFolderIdCopy(null);
    };

    const moveFocusToChild = (step) => {
        let prevIndex = 0;
        const currentFocusedIndex = focusedIndex !== null ? focusedIndex : `${currentDepth}_0`;
        const actualDepth = parseInt(currentFocusedIndex.split("_")[0])
        if (folders.folders[actualDepth - 1] && folders.folders[actualDepth] && folders.folders[actualDepth][0] &&
            folders.folders[actualDepth][0].parent && step < 0) {
            prevIndex = findParentIndexById(folders.folders[actualDepth - 1], folders.folders[actualDepth][0].parent);
        }
        setCurrentDepth(prevDepth => {
            const newDepth = actualDepth + step;
            if (step > 0 && getLastKeyValue(folders.folders).length === 0) {
                return actualDepth;
            }
            if (newDepth > 0) {
                setFocusedIndex(`${newDepth}_${prevIndex}`);
                return newDepth;
            }
            return actualDepth;
        });
    };

    const findParentIndexById = (folders, parentId) => {
        let storedIndex = 0;
        if (folders && parentId) {
            folders.forEach((folder, index) => {
                if (parentId === folder.id) {
                    storedIndex = index;
                }
            })
        }
        return storedIndex
    };

    const getLastKeyValue = (obj) => {
        const keys = Object.keys(obj);
        if (keys.length === 0) return undefined;  // Return undefined if the object is empty
        const lastKey = keys[keys.length - 1];
        return obj[lastKey];
    };

    const handleFocusClick = (folderId) => {
        const depth = selected_folders.length;
        const foldersAtDepth = folders.folders[depth];
        if (foldersAtDepth) {
            foldersAtDepth.forEach((folder, index) => {
                if (folder.id === parseInt(folderId)) {
                    setFocusedIndex(`${depth}_${index}`)
                }
            })
        }
        const linksAtDepth = folders.links[depth];
        if (linksAtDepth && linksAtDepth.some(obj => obj.id === parseInt(folderId))) {
            setFocusedIndex(`${depth}_-1`)
        }
    }

    let fetchAllFolders = true;
    useEffect(() => {
        if (folderId) {
            if (selected_folders.includes(parseInt(folderId, 10))) {
                fetchAllFolders = false;
            }
            handleFocusClick(folderId);
        } else {
            fetchAllFolders = false;
        }
    }, [selected_folders]);

    useEffect(() => {
        if (accessToken) {
            if (fetchAllFolders) {
                fetchAllFolderdetails(folderId);
            } else {
                fetchFolders();
            }
        }
    }, [accessToken]);

    const {user} = useSelector((state) => state.auth);

    return (
        user?.username ? (
            <div style={{width: '100%', overflowX: 'auto', whiteSpace: 'nowrap'}}>
                {Object.keys(folders.folders).map((key) => (
                    <div key={key} style={{display: 'inline-block', verticalAlign: 'top'}}>
                        <FoldersComponent depth={parseInt(key)} handleKeyDown={handleKeyDown}
                                          currentDepth={currentDepth} focusedIndex={focusedIndex}/>
                    </div>
                ))}
            </div>
        ) : <div>Not Logged in</div>
    );
};

const mapStateToProps = (state) => ({
    accessToken: Cookies.get('access_token'),
    folders: state.folders,
    keyboardNavigationActive: state.globalState.keyboardNavigationActive,
    itemIdToMove: state.globalState.itemIdToMove,
    selected_folders: state.folders.selected_folders
});

const mapDispatchToProps = {
    fetchFolders,
    fetchAllFolderdetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

