import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import AppBarComponent from "./components/appbar";
import {Provider} from 'react-redux'
import store from "./store";
import {Toaster} from "react-hot-toast";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        {/*<React.StrictMode>*/}
            <BrowserRouter>
                <AppBarComponent/>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <App/>
                </LocalizationProvider>
                <Toaster/>

            </BrowserRouter>
        {/*</React.StrictMode>*/}
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
