import React, {useEffect, useState} from 'react';
import {Chip, Stack, Typography} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import {deleteLinkSuccess, linkAssigneeSuccess, updateLinkSuccess} from "../redux/actions/folders.action";
import {connect, useDispatch, useSelector} from "react-redux";
import Avatar from "@mui/material/Avatar";
import {stringToColor} from "../utils/utils";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ItemDetailsModal from "./ItemDetailsModal";
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import Link from "@mui/material/Link";
import {configureKeyboardNavigation, setItemIdToMove} from "../redux/actions/global.action";
import {ContentCut} from "@mui/icons-material";
import toast from "react-hot-toast";

const {REACT_APP_BACKEND_API_URL} = process.env;

const NewTabLink = ({
                        href,
                        id,
                        selected_folders,
                        name,
                        data,
                        depth,
                        updateNameHandler,
                        on_delete,
                        is_archived
                    }) => {
    const user_settings = useSelector((state) => state.auth.user_settings);
    const isMinimalView = user_settings?.minimal_mode;

    const [openModal, setOpenModal] = useState(false);
    const [link_value, setLink] = useState(href);
    const [name_value, setName] = useState(href);
    const [linkData, setLinkData] = useState(null);
    const dispatch = useDispatch();

    const fetchLinkDetails = async () => {
        try {
            const access_token = Cookies.get("access_token")
            const response = await axios.get(`${REACT_APP_BACKEND_API_URL}/api/link/${data.id}`, {
                headers: {
                    'Authorization': `Token ${access_token}`,
                    'Content-Type': 'application/json',
                },
            });
            const linkData = await response.data;
            setLinkData(linkData);
            setLink(linkData.link.link);
            setName(linkData.link.name);
        } catch (error) {
            console.error('Error fetching folder details:', error);
        }
    };
    useEffect(() => {
        if (openModal) {
            fetchLinkDetails();
        }
    }, [openModal]);

    const handleOpenModal = (e) => {
        e.preventDefault();
        if (e.button === 2) { // Right-click (button === 2)
            setOpenModal(true);
            dispatch(configureKeyboardNavigation(false))
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        dispatch(configureKeyboardNavigation(true))
    };

    const handleUpdateLinkData = (updatedData) => {
        setLinkData(updatedData);
    };

    let selected = false;
    if (selected_folders.includes(id)) {
        selected = true
    }

    return (
        <>
            <Stack direction="row" width={"100%"} justifyContent={"space-between"} className={"folder-container"}
                   onContextMenu={handleOpenModal}
                   sx={{backgroundColor: selected ? 'rgba(71,182,175,0.25)' : ''}}>
                <Stack width={isMinimalView ? 'calc( 100% - 10px )' : 'calc( 100% - 60px )'} alignItems='flex-start' justifyContent='flex-start'>
                    <Stack direction="row" width={'100%'} spacing={0.5} sx={{
                        flex: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }} alignItems={"center"}>
                        {data.is_starred ? <StarIcon style={{color: '#d5406c'}}/> : <StarBorderIcon/>}
                        <LinkOutlinedIcon/>
                        <Link href={href} rel="noopener noreferrer" target="_blank"
                              sx={{
                                  textDecoration: 'none',
                                  width: "100%",
                                  color: 'black',
                              }}>
                            <Typography className={"text-container"}
                                        sx={{color: is_archived ? 'gray' : 'black'}}>
                                {name ? name : href}
                            </Typography>
                        </Link>
                        <ContentCut fontSize={'1px'} style={{cursor: "pointer"}} onClick={() => {
                            dispatch(setItemIdToMove(data.id))
                            toast.success("Link selected for moving.")
                        }}/>
                    </Stack>
                    {!isMinimalView && <Stack direction={"row"} width={'100%'}>
                        {data.tags.slice(0, 2).map((tag, index) => (
                            <Chip
                                key={index}
                                label={tag.name}
                                style={{marginRight: '5px', fontSize: '10px', height: '20px'}}
                            />
                        ))}
                    </Stack>}
                </Stack>
                {!isMinimalView && <Stack width={'40px'} alignItems={"center"} justifyContent={"center"}>
                        {data.employees.map((employee, index) => (index === 0 &&
                            <Avatar
                                key={index}
                                alt="No Image"
                                style={{
                                    height: 30,
                                    width: 30,
                                    borderRadius: '25%',
                                    fontSize: "11px",
                                    backgroundColor: stringToColor(employee.first_name + employee.last_name),
                                    color: 'white',
                                }}
                            >
                                {employee.first_name.charAt(0) + (employee.first_name.length > 1 ? employee.first_name.charAt(1) : '') + employee.last_name.charAt(0) + (employee.last_name.length > 1 ? employee.last_name.charAt(1) : '')}{data.employees.length > 1 ? '..' : ''}
                            </Avatar>
                        ))}
                    </Stack>}
            </Stack>

            {linkData &&
                <ItemDetailsModal isOpen={openModal} handleClose={handleCloseModal} depth={depth} itemData={data}
                                  itemInfo={linkData} updateNameHandler={updateNameHandler} href={href}
                                  setItemData={handleUpdateLinkData} on_delete={on_delete}
                                  itemType={'link'} fetchItemDetails={fetchLinkDetails}/>}
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    updateLinkSuccess,
    deleteLinkSuccess,
    linkAssigneeSuccess
};
export default connect(mapStateToProps, mapDispatchToProps)(NewTabLink);