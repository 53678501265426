import {createStore, combineReducers, applyMiddleware} from 'redux';
import authReducer from "./redux/reducers/login.reducer";
import {thunk} from "redux-thunk";
import folderReducer from "./redux/reducers/folders.reducer";
import globalReducer from "./redux/reducers/global.reducer";

const rootReducer = combineReducers({
    auth: authReducer,
    folders: folderReducer,
    globalState: globalReducer,
});

// Create the Redux store
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;