export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const FETCH_ALL_DATA_SUCCESS = "FETCH_ALL_DATA_SUCCESS";
export const FETCH_WITH_ARCHIVE_STATUS = "FETCH_WITH_ARCHIVE_STATUS";

export const ADD_FOLDER = 'ADD_FOLDER';
export const ADD_FOLDER_REQUEST = 'ADD_FOLDER_REQUEST';
export const ADD_FOLDER_FAILURE = 'ADD_FOLDER_FAILURE';
export const ADD_LINK = 'ADD_LINK';

export const RENAME_FOLDER_SUCCESS = 'RENAME_FOLDER_SUCCESS';
export const RENAME_FOLDER_FAILURE = 'RENAME_FOLDER_FAILURE';
export const UPDATE_LINK_SUCCESS = 'UPDATE_LINK_SUCCESS';
export const UPDATE_ARCHIVE_STATUS = "UPDATE_ARCHIVE_STATUS";
export const FOLDER_ASSIGNEE_SUCCESS = 'FOLDER_ASSIGNEE_SUCCESS';
export const LINK_ASSIGNEE_SUCCESS = "LINK_ASSIGNEE_SUCCESS";

export const DELETE_FOLDER = "DELETE_FOLDER";
export const DELETE_LINK = "DELETE_LINK";
export const OPEN_DETAIL_PANE = "OPEN_DETAIL_PANE";