import {
    ADD_FOLDER,
    ADD_FOLDER_FAILURE,
    ADD_FOLDER_REQUEST,
    ADD_LINK, CLOSE_DETAIL_PANE,
    DELETE_FOLDER,
    DELETE_LINK,
    FETCH_ALL_DATA_SUCCESS,
    FETCH_DATA_FAILURE,
    FETCH_DATA_SUCCESS, FETCH_WITH_ARCHIVE_STATUS,
    FOLDER_ASSIGNEE_SUCCESS, LINK_ASSIGNEE_SUCCESS, OPEN_DETAIL_PANE,
    RENAME_FOLDER_FAILURE,
    RENAME_FOLDER_SUCCESS, UPDATE_ARCHIVE_STATUS,
    UPDATE_LINK_SUCCESS,
} from "../constants/folders.constant";
import Cookies from "js-cookie";
import axios from "axios";
import toast from "react-hot-toast";

const {REACT_APP_BACKEND_API_URL} = process.env;
export const fetchDataSuccess = (folders, depth, isArchiveChanged=false) => ({
    type: FETCH_DATA_SUCCESS,
    payload: {
        folders,
        depth,
        isArchiveChanged
    },
});

export const fetchWithArchiveStatus = (folders, depth) => ({
    type: FETCH_WITH_ARCHIVE_STATUS,
    payload: { folders, depth }
});

export const fetchAllDataSuccess = (data) => ({
    type: FETCH_ALL_DATA_SUCCESS,
    payload: {
        data
    },
});

export const fetchChildDataSuccess = (folders, depth, folder_id) => ({
    type: FETCH_DATA_SUCCESS,
    payload: {
        folders,
        depth,
        folder_id: folder_id,
        child: true,

    },
});

export const fetchDataFailure = (error) => ({
    type: FETCH_DATA_FAILURE,
    payload: error,
});


export const addFolder = (folderName, depth, parent_folder_id) => {
    return async (dispatch) => {
        dispatch({type: ADD_FOLDER_REQUEST});

        try {
            const accessToken = Cookies.get('access_token');
            let url;
            if (parent_folder_id) {
                url = `${REACT_APP_BACKEND_API_URL}/api/folder/create/${parent_folder_id}`
            } else {
                url = `${REACT_APP_BACKEND_API_URL}/api/folder/create/`
            }

            const response = await axios.post(url, {name: folderName},
                {
                    headers: {
                        'Authorization': `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
            toast.success('Folder Created Successfully');

            const data = response.data;

            dispatch({
                type: ADD_FOLDER,
                payload: {
                    data,
                    depth
                },
            });
        } catch (error) {
            toast.success('Error Creating Folder');

            dispatch({
                type: ADD_FOLDER_FAILURE,
                payload: error.message || 'Failed to create folder',
            });
        }
    };
};


export const addLink = (link, name, depth, folder_id) => {
    return async (dispatch) => {

        try {
            const accessToken = Cookies.get('access_token');
            let url;
            if (folder_id) {
                url = `${REACT_APP_BACKEND_API_URL}/api/link/create/${folder_id}`
            } else {
                url = `${REACT_APP_BACKEND_API_URL}/api/link/create/`
            }

            const response = await axios.post(url, {link: link, name: name},
                {
                    headers: {
                        'Authorization': `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
            toast.success('Link Saved Successfully');

            const data = response.data;

            dispatch({
                type: ADD_LINK,
                payload: {
                    data,
                    depth
                },
            });
        } catch (error) {
            toast.success('Error Saving Link');

            dispatch({
                type: ADD_FOLDER_FAILURE,
                payload: error.message || 'Failed to create link',
            });
        }
    };
};


export const fetchFolders = (isChangeArchive=false) => {
    const archiveStatus = Cookies.get('archiveStatus');
    return async (dispatch, getState) => {
        try {
            const accessToken = Cookies.get('access_token');

            let url = `${REACT_APP_BACKEND_API_URL}/api/folders/`;
            if (archiveStatus !== null && archiveStatus !== undefined) {
                url = url + `?archive_status=${archiveStatus}`;
            }
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const folders = await response.data;
            if (!isChangeArchive) {
                dispatch(fetchDataSuccess(folders, 1));
            } else {
                dispatch(fetchWithArchiveStatus(folders, 1));
            }
        } catch (error) {
            dispatch(fetchDataFailure(error)); // Dispatch failure action with error
        }
    };
};

export const fetchAllFolderdetails = (folderId) => {
    const archiveStatus = Cookies.get('archiveStatus');
    return async (dispatch, getState) => {
        try {
            const accessToken = Cookies.get('access_token');
            let url = `${REACT_APP_BACKEND_API_URL}/api/folder/structure/${folderId}`
            if (archiveStatus !== null && archiveStatus !== undefined) {
                url = url + `?archive_status=${archiveStatus}`;
            }
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.data;
            dispatch(fetchAllDataSuccess(data));
        } catch (error) {
            dispatch(fetchDataFailure(error)); // Dispatch failure action with error
        }
    };
};


export const fetchChildFolders = (folder_id, depth, archiveStatus= null) => {
    return async (dispatch, getState) => {
        try {
            const accessToken = Cookies.get('access_token');
            let url = `${REACT_APP_BACKEND_API_URL}/api/folders/${folder_id}/`
            if (archiveStatus !== null && archiveStatus !== undefined) {
                url = url + `?archive_status=${archiveStatus}`;
            }

            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const folders = await response.data;
            dispatch(fetchChildDataSuccess(folders, depth + 1, folder_id)); // Dispatch success action with fetched data
        } catch (error) {
            dispatch(fetchDataFailure(error)); // Dispatch failure action with error
        }
    };
};

export const renameFolderSuccess = (folder, depth) => ({
    type: RENAME_FOLDER_SUCCESS,
    payload: {folder, depth}
});

export const updateArchiveStatus = (status) => ({
    type: UPDATE_ARCHIVE_STATUS,
    payload: {status}
})

export const updateLinkSuccess = (link, depth) => ({
    type: UPDATE_LINK_SUCCESS,
    payload: {link, depth}
});

export const renameFolderFailure = (error) => ({
    type: RENAME_FOLDER_FAILURE,
    payload: error
});

export const deleteLinkSuccess = (link_id, depth) => ({
    type: DELETE_LINK,
    payload: {
        link_id,
        depth
    }
})

export const deleteFolderSuccess = (folder_id, depth) => ({
    type: DELETE_FOLDER,
    payload: {
        folder_id,
        depth
    }
})

export const updateLinkAssignee = (id, onSelectAssignee, linkData, isDelete = false) => {
    return async (dispatch, getState) => {
        try {
            const accessToken = Cookies.get('access_token');
            const response = await axios.post(
                `${REACT_APP_BACKEND_API_URL}/api/link/update_assignee/${linkData.link.id}`,
                {
                    assignee: id,
                    is_delete: isDelete
                },
                {
                    headers: {
                        Authorization: `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (id === null) {
                toast.success('Unassigned Folder')
                onSelectAssignee(null, response.data.comment);
            } else {
                onSelectAssignee(response.data.link?.employees, response.data.comment);
                toast.success('Assignee Updated Successfully.');
            }
        } catch (error) {
            toast.error('Error Updating Assignee');
        }
    }
}

export const updateFolderAssignee = (id, onSelectAssignee, folderData, isDelete = false) => {
    return async (dispatch) => {
        try {
            const accessToken = Cookies.get('access_token');
            const response = await axios.post(
                `${REACT_APP_BACKEND_API_URL}/api/folder/update_assignee/${folderData.folder.id}`,
                {
                    assignee: id,
                    is_delete: isDelete
                },
                {
                    headers: {
                        Authorization: `Token ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (id === null) {
                toast.success('Unassigned Folder')
                onSelectAssignee(null, response.data.comment);
            } else {
                onSelectAssignee(response.data.folder.employees, response.data.comment);
                toast.success('Assignee Updated Successfully.');
            }
        } catch (error) {
            toast.error('Error Updating Assignee');
        }
    }
}

export const folderAssigneeSuccess = (folder, depth) => ({
    type: FOLDER_ASSIGNEE_SUCCESS,
    payload: {folder, depth}
});

export const linkAssigneeSuccess = (link, depth) => ({
    type: LINK_ASSIGNEE_SUCCESS,
    payload: {link, depth}
});

export const openDetailPane = (folder_id) => ({
    type: OPEN_DETAIL_PANE,
    folder_id: folder_id
});

export const closeDetailPane = () => ({
    type: OPEN_DETAIL_PANE,
    folder_id: null
});
