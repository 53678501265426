import React, {useEffect, useRef, useState} from 'react';
import {IconButton, Stack} from "@mui/material";
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import Tooltip from '@mui/material/Tooltip';
import toast from "react-hot-toast";
import {
  TranscribeStreamingClient,
  StartStreamTranscriptionCommand,
} from "@aws-sdk/client-transcribe-streaming";
import MicrophoneStream from "microphone-stream";
import { Buffer } from "buffer";

const AudioTranscriber = ({ onTranscriptionStart, onTranscriptionDataUpdate, onTranscriptionEnd }) => {

    const language = "en-US";
    const SAMPLE_RATE = 44100;
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const [audioBlob, setAudioBlob] = useState(null);
    const microphoneStream = useRef(null);
    let transcribeClient = useRef(null);

    const createMicrophoneStream = async () => {
        try {
            microphoneStream.current = new MicrophoneStream()
            microphoneStream.current.setStream(
                await window.navigator.mediaDevices.getUserMedia({
                    video: false,
                    audio: true,
                })
            );
            return true;
        } catch (e) {
            toast.error('Microphone access denied or not found.');
            setIsRecording(false);
            return false;
        }
    };

    const createTranscribeClient = () => {
        const {REACT_APP_AWS_TRANSCRIBE_REGION, REACT_APP_AWS_TRANSCRIBE_ACCESS_KEY_ID, REACT_APP_AWS_TRANSCRIBE_ACCESS_KEY_SECRET} = process.env
        transcribeClient.current = new TranscribeStreamingClient({
            region: REACT_APP_AWS_TRANSCRIBE_REGION,
            credentials: {
                accessKeyId: REACT_APP_AWS_TRANSCRIBE_ACCESS_KEY_ID,
                secretAccessKey: REACT_APP_AWS_TRANSCRIBE_ACCESS_KEY_SECRET,
            },
        });
    };

    const encodePCMChunk = (chunk) => {
        const input = MicrophoneStream.toRaw(chunk);
        let offset = 0;
        const buffer = new ArrayBuffer(input.length * 2);
        const view = new DataView(buffer);
        for (let i = 0; i < input.length; i++, offset += 2) {
            let s = Math.max(-1, Math.min(1, input[i]));
            view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
        }
        return Buffer.from(buffer);
    };

    const getAudioStream = async function* () {
        for await (const chunk of microphoneStream.current) {
            if (chunk.length <= SAMPLE_RATE) {
                yield {
                    AudioEvent: {
                        AudioChunk: encodePCMChunk(chunk),
                    },
                };
            }
        }
    };

    const startStreaming = async (language, onTranscriptionDataUpdate) => {
        const command = new StartStreamTranscriptionCommand({
            LanguageCode: language,
            MediaEncoding: "pcm",
            MediaSampleRateHertz: SAMPLE_RATE,
            AudioStream: getAudioStream(),
        });
        const data = await transcribeClient.current.send(command);
        for await (const event of data.TranscriptResultStream) {
            const results = event.TranscriptEvent.Transcript.Results;
            if(results.length) {
                onTranscriptionDataUpdate(results[0].Alternatives[0].Transcript, results[0]?.IsPartial)
            }
        }
    };

    const startRecording = async (callback) => {
        if (microphoneStream.current || transcribeClient.current) {
            stopRecording();
        }
        if(await createMicrophoneStream()) {
            setIsRecording(true);
            await createTranscribeClient();
            await startStreaming(language, onTranscriptionDataUpdate);
        }
    };

    const handleClearRecording = () => {
        setAudioBlob(null);
        audioChunks.current = [];
    };

    const handleStartRecording = async () => {
        onTranscriptionStart()
        try{
            startRecording(null);
        } catch (e) {
            toast.error('Error during auto transcrition.');
        }
    };

    const stopRecording = function () {
        onTranscriptionEnd()
        if (microphoneStream.current) {
            microphoneStream.current.stop();
            microphoneStream.current.destroy();
            microphoneStream.current = undefined;
        }
    };

    const handleStopRecording = () => {
        setIsRecording(false);
        stopRecording();
    };

    return (
        <Stack width={audioBlob ? "100%" : "fit-content"}>
            {!audioBlob && <Tooltip title="Voice Transcription"><IconButton onClick={() => {
                !isRecording ? handleStartRecording() : handleStopRecording();
            }}>
                <VoiceChatIcon sx={isRecording ? { color: "red" } : { color: "gray" }} />
            </IconButton></Tooltip>}
        </Stack>
    );
};
export default AudioTranscriber;